@charset "utf-8";

/* ----------------------------------------------------------------
   theme/newcar
/  --------------------------------------------------------------*/


.page-header {
  &.-newcar {
    background-image: url(../img/newcar/title.jpg);
    div {
      &:after {
        display: none;
      }
    }
  }
@include media(sp) {
  &.-newcar {
    margin-bottom: 120px;
    div {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}
}

.filter-area {
  margin-bottom: 100px;
  padding: 30px;
  border: 8px solid #e8e8e8;
  background: #fff;
  &__title {
    margin: 0 0 10px;
    font-size: rem(21);
    text-align: center;
  }
  &-table {
    th,td {
      border-top: 1px solid #e8e8e8;
    }
    th {
      width: 10em;
      padding: 15px 7px;
      color: #f1a63b;
      font-size: rem(18);
      vertical-align: top;
      text-align: left;
    }
    td {
      padding: 15px 0;
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          display: inline-block;
          margin-right: 30px;
          margin-bottom: 10px;
        }
        input {
          margin-right: 5px;
        }
      }
    }
  }
@include media(sp) {
  margin-bottom: 50px;
  padding: 15px;
  border-width: 4px;
  &-table {
    th,td {
      display: block;
      width: 100%;
      box-sizing: border-box;
    }
    td {
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 50%;
          margin: 0 0 10px;
        }
      }
    }
  }
}
}

.product-area {
  display: flex;
  flex-wrap: wrap;
  margin-right: -25px;
@include media(sp) {
  margin: 0;
  display: block;
}
}

.product-item {
  position: relative;
  width: 230px;
  margin-right: 25px;
  margin-bottom: 25px;
  padding: 20px;
  border: 1px solid #53d1ed;
  border-radius: 11px;
  box-sizing: border-box;
  background: #fff;
  overflow: hidden;
  &:nth-child(4n) {
    //margin-right: 0;
  }
  &__img {
    text-align: center;
  }
  &__title {
    margin: 20px 0 15px;
    text-align: center;
    font-size: rem(18);
    span {
      display: inline-block;
      min-width: 130px;
      padding: 0 10px;
      border-bottom: 2px solid #ffc878;
    }
  }
  &__price {
    margin: 0;
    font-size: rem(18);
  }
  &__capacity {
    margin: 0 0 5px;
  }
  &-type {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      margin: 0 5px 5px 0;
      padding: 3px 15px;
      font-size: rem(14);
      color: #fff;
    }
    .-type01 {
      background: #1571d3;
    }
    .-type02 {
      background: #ccc816;
    }
    .-type03 {
      background: #b7afa3;
    }
    .-type04 {
      background: #cc1515;
    }
    .-type05 {
      background: #f1a63b;
    }
    .-type06 {
      background: #00b900;
    }
    .-type07 {
      background: #ca145a;
    }
    .-type08 {
      background: #f8a5a5;
    }
    .-type09 {
      background: #53d1ed;
    }
  }
  &-detail {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,.9);
    transition: .5s;
  }
  &-links {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin-bottom: 10px;
      a {
        display: block;
        padding: 10px 30px;
        text-align: center;
        border-radius: 25px;
        font-size: rem(14);
      }
      &:nth-child(1) a {
        background: #84e9af;
      }
      &:nth-child(2) a {
        background: #f8a5a5;
      }
      &:nth-child(3) a {
        background: #ffc878;
      }
      &:nth-child(4) a {
        background: #53d1ed;
      }
    }
  }
  &:hover {
    .product-item-detail {
      opacity: 1;
    }
  }
@include media(sp) {
  width: auto;
  margin-right: 0;
  &-detail {
    position: static;
    height: auto;
    background: none;
    opacity: 1;
  }
  &-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px -10px -10px;
    li {
      width: 49.2%;
      margin-bottom: 5px;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 10px;
        border-radius: 0px;
        font-size: rem(12);
        box-sizing: border-box;
      }
    }
  }
}

}
