@charset "utf-8";

/* ----------------------------------------------------------------
   theme/top-news
/  --------------------------------------------------------------*/

.top-news {
  width: 70%;
  margin: 0 auto;
  &-slider {
    position: relative;
  }
  &__item {
    a {
      color: #000;
    }
  }
  &__img {
    border-radius: 35px;
    border: 1px solid #000;
    overflow: hidden;
    box-sizing: border-box;
    line-height: 0;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //width: 313px;
  }
  &__date {
    font-size: rem(12);
  }
  &__category {
    @extend %archive-category;
    min-width: 6em;
    padding: 5px;
    line-height: 1;
    border-radius: 3px;
    text-align: center;
    font-size: rem(14);
  }
  .swiper-button-prev {
    top: 90px;
    left: -60px;
    width: 38px;
    height: 58px;
    margin-top: 0;
    background: url(../img/top/i_prev.png) no-repeat;
  }
  .swiper-button-next {
    top: 90px;
    right: -60px;
    width: 38px;
    height: 58px;
    margin-top: 0;
    background: url(../img/top/i_next.png) no-repeat;
  }

@include media(sp) {
  width: auto;
  margin: 0 20px;
  &__header {
    width: auto;
  }
  .swiper-button-prev {
    top: 20%;
    left: -15px;
    width: 30px;
    height: 50px;
    background-size: contain;
  }
  .swiper-button-next {
    top: 20%;
    right: -15px;
    width: 30px;
    height: 50px;
    background-size: contain;
  }
}

}
