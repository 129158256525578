@charset "utf-8";

/* ----------------------------------------------------------------
   theme/top-contents
/  --------------------------------------------------------------*/

.top-contents {
  &-list {
    width: 1000px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    &__text {
      margin: 0 5px;
      padding: 10px;
      color: #fff;
      font-size: rem(34);
      font-weight: bold;
      font-family:"ヒラギノ丸ゴ Pro W4","ヒラギノ丸ゴ Pro","Hiragino Maru Gothic Pro","HG丸ｺﾞｼｯｸM-PRO","HGMaruGothicMPRO";
      background: rgba(0,0,0,.2);
      border-radius: 3px;
      text-align: center;
    }
    li {
      img {
        display: none;
        position: absolute;
        bottom: 10px;
        left: 10px;
      }
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        transition: .5s;
        border-radius: 10px;
        &:hover {
          opacity: 1;
          //background: rgba(0,0,0,.2);
          //border-radius: 10px;
          img {
            display: block;
          }
          .top-contents-list__text {
            display: flex;
          }
        }
      }
    }
    %__item {
      position: relative;
      width: 300px;
      height: 300px;
      margin-bottom: 30px;
      background-repeat: no-repeat;
      background-size: contain;
    }
    &__item {
      &--item01 {
        @extend %__item;
        background-image: url(../img/top/c_item_img01.png);
      }
      &--item02 {
        @extend %__item;
        background-image: url(../img/top/c_item_img02.png);
      }
      &--item03 {
        @extend %__item;
        background-image: url(../img/top/c_item_img03.png);
        span {
          padding: 10px 30px;
        }
      }
      &--item04 {
        @extend %__item;
        background-image: url(../img/top/c_item_img04.png);
      }
      &--item05 {
        @extend %__item;
        background-image: url(../img/top/c_item_img05.png);
        span {
          padding: 10px 30px;
        }
      }
      &--item06 {
        @extend %__item;
        background-image: url(../img/top/c_item_img06.png);
        span {
          padding: 10px 5px;
        }
      }
    }

  }
@include media(tab) {
  &-list {
    width: auto;
    margin: 0 20px;
    justify-content: center;
    li {
      &:nth-child(odd) {
        margin-right: 20px;
      }
      &:nth-child(even) {
        margin-left: 20px;
      }
    }
  }
}
@include media(sp) {
  &-list {
    width: auto;
    margin: 0 20px;
    &__text {
      position: absolute;
      display: block;
      top: auto;
      bottom: 0;
      width: 100%;
      height: auto;
      font-size: rem(20);
      text-align: right;
      margin: 0;
      padding: 10px;
      box-sizing: border-box;
      background: rgba(0,0,0,.2);
      border-radius: 0 0 10px 10px;
    }
    li {
      margin: 0 0 20px !important;
      img {
        display: block;
        bottom: auto;
        top: 10px;
      }
    }
    %__item {
      height: 150px;
      background-size: cover;
      border-radius: 10px;
    }
    &__item {
      &--item06 {
        span {
          font-size: rem(18);
        }
      }
    }
    
  }

}
}
