@charset "utf-8";

/* ----------------------------------------------------------------
   layout/footer
/  --------------------------------------------------------------*/

.l-footer {
  padding: 60px 0 0;
  background: #ffe68f;
  a {
    color: #000;
  }
  &-inner {
    position: relative;
    width: 1000px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  &-info {
    &__logo{
      margin: 0 0 10px;
    }
    &__address {
      margin-bottom: 10px;
    }
    &__sns {
      display: flex;
      justify-content: space-between;
      width: 135px;
      list-style: none;
      margin: 0 0 10px;
      padding: 0;
      li {
        width: 40px;
        height: 40px;
        font-size: 0;
        a {
          display: block;
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
        }
        .-twitter {
          background-image: url(../img/share/i_twitter.png);
        }
        .-facebook {
          background-image: url(../img/share/i_facebook.png);
        }
        .-line {
          background-image: url(../img/share/i_line.png);
        }
        .-instagram {
          background-image: url(../img/share/i_instagram.png);
        }
      }
    }
    &__bnr {
      margin: 0;
    }
  }
  &-nav {
    width: 70%;
    display: flex;
    justify-content: space-between;
    &-item {
      &__title {
        margin: 0;
        font-size: rem(14);
      }
      &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        font-size: rem(13);
        li {
          &:before {
            margin-right: .5em;
            font-family: "FontAwesome";
            content: "\f0da";
            color: #fff;
          }
        }
      }
    }
  }
  &-message {
    position: absolute;
    width: 314px;
    height: 150px;
    bottom: -40px;
    right: 0;
    background: url(../img/share/f_img.png) right bottom no-repeat;
  }

@include media(tab) {
  padding: 20px 0;
  padding-bottom: 80px;
  &-inner {
    width: auto;
    padding: 0 20px;
    display: block;
  }
  &-info {
    &__sns {
      margin: 0 auto 10px;
    }
    &__bnr {
      margin-bottom: 20px;
    }
  }
  &-nav {
    width: auto;
    display: block;
    &-item {
      margin-bottom: 20px;
      &__title {
        margin-bottom: 10px;
      }
      &__list {
        background: #fff;
        border: 1px solid #c7c7c7;
        li {
          padding: 5px;
          border-bottom: 1px solid #ffe68f;
          box-sizing: border-box;
          &:before {
            color: #ffe68f;
          }
        }
        &.-shop-list {
          display: flex;
          flex-wrap: wrap;
          li {
            width: 50%;
          }
        }
      }
    }
  }
  &-message {
    position: static;
    display: flex;
    align-items: center;
    width: 100%;
    height: 74px;
    background-size: 91px 74px;
    background-position: right center;
    box-sizing: border-box;
  }
}
}

.copyright {
  width: 1000px;
  margin: 0 auto;
  padding-bottom: 60px;

@include media(tab) {
  width: auto;
  text-align: center;
  padding-bottom: 0;
}
}
