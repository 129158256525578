@charset "utf-8";

/* ----------------------------------------------------------------
   layout/main
/  --------------------------------------------------------------*/

main {

@include media(sp) {
}

}
