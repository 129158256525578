@charset "utf-8";

/* ----------------------------------------------------------------
   component/breadcrumb
/  --------------------------------------------------------------*/


.breadcrumb {
  padding: 30px 0 12px;
  background: #fff;
  ul {
    @extend %contents-width;
    list-style: none;
    padding: 0;
    display: flex;
    line-height: 1;
    font-size: rem(14);
  }
  li {
    &:first-child {
      &:before {
        display: inline-block;
        content: " ";
        margin: -.2em 0.5em 0;
        width: 12px;
        height: 12px;
        background: url(../img/share/icon_home.png) no-repeat;
        vertical-align: middle;
      }
    }
    &:after {
      display: inline-block;
      content: " ";
      margin: 0 0.5em;
      width: 12px;
      height: 12px;
      background: url(../img/share/icon_link.png) center center no-repeat;
      vertical-align: middle;
    }
    &:last-child:after {
      display: none;
    }
  }
@include media(sp) {
  display: none;
}
}
