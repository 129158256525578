@charset "utf-8" ;

// ----------------------------------------------------------------
// function.scss - Event.scss
// -----------------------------------------------------------------

///
// media
// @param string
// @return media query and content
///
@mixin media($bp) {

  @if $bp == 'sp' {
    @media screen and (max-width: 750px) {
      @content;
    }
  }

  @else if $bp == 'tab' {
    @media screen and (max-width: 960px) {
      @content;
    }
  }
  
  @else if $bp == 'mid' {
    @media screen and (min-width: 768px) {
      @content;
    }
  }

  @else if $bp == 'pc' {
    @media screen and (min-width: 1024px) {
      @content;
    }
  }

}

%contents-width {
  width: 1000px;
  margin: 0 auto;
@include media(tab) {
  width: auto;
}
@include media(sp) {
  width: auto;
  margin: 0 15px;
}
}

%font-notosans {
  font-family: 'Noto Sans Japanese', Sans-Serif;
}

%btn-gradation {
  position: relative;
  display: inline-block;
  width: 350px;
  margin: 0 auto;
  text-align: center;
  padding: 25px;
  background: #00aee6; /* Old browsers */
  background: radial-gradient(ellipse at center,  #00aee6 1%,#53d1ed 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00aee6', endColorstr='#53d1ed',GradientType=1 );
  color: #fff;
  font-size: rem(22);
  text-align: center;
  line-height: 1;
  box-sizing: border-box;
  font-weight: bold;
@include media(sp) {
  width: auto;
  min-width: 260px;
  font-size: rem(20);
}
}
