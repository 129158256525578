@charset "utf-8";

/* ----------------------------------------------------------------
   theme/top-calender
/  --------------------------------------------------------------*/

.top-calender {
  width: 820px;
  margin: 0 auto;
@include media(tab) {
  width: auto;
  margin: 0 20px;
}

}


