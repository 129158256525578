@charset "utf-8";

/* ----------------------------------------------------------------
   theme/recruit
/  --------------------------------------------------------------*/


.page-header {
  &.-recruit {
    margin-bottom: 200px;
    background-image: url(../img/recruit/title.jpg);
    div {
      padding-left: 60px;
      padding-right: 60px;
      &:after {
        top: 20px;
        right: 30px;
        width: 30px;
        height: 30px;
        background: url(../img/recruit/img_title.png) no-repeat;
      }
    }
  }
@include media(sp) {
  &.-recruit {
    margin-bottom: 120px;
    div {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}
}

.recruit {
  &-header {
    @extend %contents-width;
    position: relative;
    margin-bottom: 130px;
    padding-bottom: 40px;
    &-box {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 50px;
      background: #84e9af;
      &__title {
        @extend %font-notosans;
        margin: 0 0 30px;
        font-size: rem(28);
        text-align: cente;
        &:after {
          display: block;
          content: " ";
          width: 165px;
          height: 2px;
          margin: 20px auto 0;
          background: #fff;
        }
      }
    }
  }
@include media(sp) {
  &-header {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 70px;
    padding-bottom: 0;
    &-box {
      width: 100%;
      position: static;
      margin-top: -5px;
      padding: 25px;
      text-align: center;
      order: 1;
    }
    &__img {
      order: 0;
    }
  }
}
  &-headline {
    flex-shrink: 0;
    width: 100%;
    margin: 0 0 45px;
    font-size: rem(26);
    text-align: center;
    &:after {
      display: block;
      content: " ";
      width: 270px;
      height: 5px;
      margin: 10px auto 0;
      background: #84e9af;
    }
  }
@include media(sp) {

}
  
  &-info {
    @extend %contents-width;
    margin-bottom: 80px;
    &-list {
      position: relative;
      list-style: none;
      margin: 0 60px 60px;
      padding: 20px 35px;
      background: #e4e4e4;
      border-radius: 15px;
      li {
        display: flex;
        font-size: rem(24);
        .date {
          flex-shrink: 0;
          margin-right: 1em;
        }
      }
      &:after {
        position: absolute;
        display: block;
        content: " ";
        right: -42px;
        bottom: -17px;
        width: 160px;
        height: 130px;
        background: url(../img/recruit/img02.png) no-repeat;
        background-size: contain;
      }
    }
  }
@include media(sp) {
  &-info {
    &-list {
      margin: 0 0 30px;
      padding: 15px;
      li {
        display: block;
        margin-bottom: 10px;
        font-size: rem(16);
        &:last-child {
          margin: 0;
        }
        .date {
          display: block;
        }
      }
      &:after {
        right: -10px;
        width: 80px;
        height: 65px;
      }
    }
  }
}

  &-btn {
    text-align: right;
    margin-right: 60px;
    &__link {
      position: relative;
      display: inline-block;
      padding: 15px 50px;
      padding-right: 80px;
      border: 2px solid #84e9af;
      background: #fff;
      font-weight: bold;
      color: #84e9af;
      &:after {
        position: absolute;
        right: 30px;
        top: 50%;
        margin-top: -11.5px;
        display: inline-block;
        content: " ";
        width: 23px;
        height: 23px;
        background: url(../img/recruit/icon_link.png) no-repeat;
        background-size: contain;
      }
    }
  }
@include media(sp) {

}

  &-president {
    @extend %contents-width;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 120px;
    &-box {
      text-align: center;
      &__title {
        margin: 0 0 30px;
        font-size: rem(36);
        color: #84e9af;
      }
      &__text {
        margin-bottom: 40px;
      }
      .recruit-btn {
        margin: 0;
        text-align: center;
      }
      
    }
  }
@include media(tab) {
  &-president {
    display: block;
    margin-bottom: 70px;
    &__img {
      text-align: center;
    }
    &-box {
      text-align: center;
      &__title {
        margin: 0 0 20px;
        font-size: rem(28);
      }      
    }
  }
}

  &-works {
    @extend %contents-width;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 130px;
    &-item {
      text-align: center;
      &__title {
        margin: 20px 0 10px;
        font-size: rem(21);
      }
    }
  }
@include media(tab) {
  &-works {
    width: 620px;
    
  }
}
@include media(sp) {
  &-works {
    width: auto;
    margin-bottom: 70px;
    display: block;
    &-item {
      margin-bottom: 20px;
      &__title {
        margin: 10px 0;
      }
    }
  }
}
  
  &-senior {
    margin-bottom: 85px;
    &-section {
      display: flex;
      justify-content: center;
      min-height: 500px;
      &-detail {
        position: relative;
        width: 50%;
        padding: 50px 0;
        background: #53d1ed;
        &:before {
          position: absolute;
          display: block;
          content: " ";
          top: 50%;
          right: -34px;
          width: 68px;
          height: 68px;
          margin-top: -34px;
          transform: rotate(-45deg);
          z-index: 1;
          background: #53d1ed;
        }
        &-inner {
          width: 65%;
          margin: 0 auto;
        }
        &__name {
          margin: 0 0 40px;;
          padding-bottom: 15px;
          font-size: rem(28);
          line-height: 1;
          border-bottom: 1px solid #000;
          text-align: center;
          @extend %font-notosans;
          font-weight: bold;
          &-ruby {
            display: inline-block;
            margin-top: 15px;
            font-size: rem(18);
            color: #fff;
          }
        }
        &__headline {
          margin: 0 0 30px;
          font-size: rem(24);
          font-weight: bold;
          letter-spacing: -.08em;
        }
        &__img {
          line-height: 0;
          display: flex;
          width: 50%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          div {
            height: 100%;
          }
          &.-voice01 {
            background-image: url(../img/recruit/img_voice01.jpg);
          }
          &.-voice02 {
            background-image: url(../img/recruit/img_voice02.jpg);
          }
          &.-voice03 {
            background-image: url(../img/recruit/img_voice03.jpg);
          }
          &.-voice04 {
            background-image: url(../img/recruit/img_voice04.jpg);
          }
        }
      }
      &:nth-child(even) {
        .recruit-senior-section-detail {
          order: 1;
          background: #84e9af;
          &:before {
            right: auto;
            left: -34px;
            background: #84e9af;
          }
        }
        .recruit-senior-section__img {
          order: 0;
        }
      }
    }
  }
@include media(tab) {
  &-senior {
    &-section {
      flex-direction: column;
      min-height: 100px;
      &-detail {
        order: 1;
        width: 100%;
        &__img {
          order: 0;
          width: 100%;
          height: 350px;
        }
      }
      &:nth-child(even) {
        .recruit-senior-section-detail {
          order: 1;
        }
        .recruit-senior-section__img {
          order: 0;
        }
      }
    }
  }
}
@include media(sp) {
  &-senior {
    margin-bottom: 70px;
    &-section {
      flex-direction: column;
      &-detail {
        width: auto;
        padding: 40px 20px 20px;
        order: 1;
        &:before {
          top: 17px;
          margin-left: -17px;
          left: 50%;
          right: auto;
          width: 34px;
          height: 34px;
        }
        &-inner {
          width: auto;
        }
        &__name {
          margin-bottom: 20px;
          font-size: rem(24);
        }
        &__headline {
          margin: 0 0 20px;
          font-size: rem(20);
          letter-spacing: 0em;
        }
        &__img {
          height: 250px;
        }
      }
      &:nth-child(even) {
        .recruit-senior-section-detail {
          order: 1;
          background: #84e9af;
          &:before {
            right: auto;
            left: 50%;
            background: #84e9af;
          }
        }
        .recruit-senior-section__img {
          order: 0;
        }
      }
    }
  }
}

  &-contact {
    &-body {
      //background: #fff;
      //box-shadow: 0 0 3px #ccc;
      &__headline {
        margin: 0 0 12px;
        padding-left: 10px;
        font-weight: normal;
        @extend %font-notosans;
        border-left: 5px solid #82e7ad;
        font-size: rem(24);
      }
      &-agreement {
        padding-top: 10px;
        border-top: 1px solid #000;
        &-body {
          height: 300px;
          padding: 20px;
          border: 1px solid #000;
          background: #fff;
          overflow: auto;
        }
      }
    }
  }
@include media(sp) {
  &-contact {
    &-body {
      &-agreement {
        &-body {
          height: 200px;
        }
      }
    }
  }
}

  // 社長からのメッセージ
  &-message {
    position: relative;
    left: -19px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 150px;
    &__img {
      flex-shrink: 0;
      img {
        display: block;
      }
      img + img {
        margin-top: 12px;
      }
    }
    &-box {
      position: relative;
      left: -35px;
      width: 416px;
      flex-shrink: 0;
      padding: 50px 35px;
      background: #0071b9;
      box-sizing: border-box;
      &__title {
        margin: 0 0 30px;
        padding-bottom: 15px;
        font-size: rem(24);
        color: #fff;
        border-bottom: 1px solid #fff;
        text-align: center;
      }
      &__text {
        p {
          margin-bottom: 35px;
          &:last-child {
            margin: 0;
          }
        }
      }
    }
    &__sign {
      padding: 25px 45px 0 60px;
      order: 0;
      img {
        display: block;
        margin-bottom: 12px;
        &:last-child {
          float: right;
          margin: 0;
        }
      }
    }
    &.-message01 {
      .recruit-message {
        &-detail {
          display: flex;
          flex-direction: column;
        }
        &__sign {
          order: 0;
        }
        &-box {
          order: 1;
          
          &__text {
            color: #fff;
          }
        }
      }
    }
    &.-message02 {
      .recruit-message {
        margin-bottom: 50px;
        &__img {
          order: 1;
        }
        &-box {
          left: 0;
          top: -60px;
          margin-right: -35px;
          order: 0;
          background: #84e9af;
          &__title {
            color: #000;
            border-color: #000;
          }
        }
      }
    }
    &.-message03 {
      .recruit-message {
        &-box {
          width: 528px;
          top: 55px;
          background: #53d1ed;
        }
      }
    }
  }
@include media(sp) {
  &-message {
    position: static;
    left: 0;
    flex-direction: column;
    margin: 0 -15px;
    &__img {
      order: 2;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin: 0;
      img {
        width: 50%;
        margin: 0 !important;
      }
    }
    &-box {
      order: 1;
      position: static;
      left: 0;
      width: auto;
      padding: 35px 25px;
      &__title {
        margin: 0 0 20px;
        padding-bottom: 10px;
        font-size: rem(20);
      }
      &__text {
        p {
          margin-bottom: 20px;
          &:last-child {
            margin: 0;
          }
        }
      }
    }
    &__sign {
      padding: 25px 45px 0 60px;
      order: 0;
      img {
        display: block;
        margin-bottom: 12px;
        &:last-child {
          float: right;
          margin: 0;
        }
      }
    }
    &.-message01 {
      .recruit-message {
        &-detail {
          display: flex;
          flex-direction: column;
        }
        &__img {
          display: block;
          img {
            width: auto;
          }
        }
        &__sign {
          position: relative;
          order: 2;
          padding: 20px;
          padding-left: 60px;
          line-height: 0;
          img {
            width: 51.3%;
            margin: 0;
            &:last-child {
              position: absolute;
              bottom: 0;
              right: 20px;
              width: 20.4%;
            }
          }
        }
      }
    }
    &.-message02 {
      .recruit-message {
        margin-bottom: 50px;
        &-box {
          margin-right: 0;
        }
      }
    }
    &.-message03 {
      .recruit-message {
        &-box {
          width: auto;
        }
      }
    }
  }
}
}

.tab {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  //overflow: hidden;
  li {
    position: relative;
    width: 34%;
    //background: #fff;
    //overflow: hidden;
    font-size: rem(18);
    color: #a7a7a7;
    line-height: 1.2;
    &:after {
      position: absolute;
      right: 0;
      top: 5px;
      top: 0;
      display: block;
      content: " ";
      width: 54px;
      //height: 80px;
      height: 100%;
      //background: #fff;
      //transform: rotate(-30deg);
      //box-shadow: 1px 1px 2px #ccc;
      z-index: 4;
      background: url(../img/recruit/line.png) no-repeat;
      background-size: cover;
    }
    a {
      display: flex;
      align-items: center;
      width: 100%;
      height: 70px;
      padding: 15px 0;
      padding-left: 30px;
      background: #fff;
      box-sizing: border-box;
      box-shadow: 0 -3px 3px -3px #ccc;
      //background: #fff url(../img/recruit/line.png) right center no-repeat;
      //background-size: contain;
      &.active {
        font-size: rem(28);
        color: #82e7ad;
      }
    }
    &:hover {
      a {
        background-color: #82e7ad;
        color: #fff;
        cursor: pointer;
        opacity: 1;
      }
      &:after {
        background-image: url(../img/recruit/line_over.png);
      }
      &:before {
        position: absolute;
        left: -54px;
        top: 0;
        display: block;
        content: " ";
        width: 54px;
        height: 100%;
        z-index: 4;
        background: url(../img/recruit/line_over2.png) no-repeat;
        background-size: cover;
      }
    }
    &:first-child {
      margin-left: -5px;
      padding-left: 5px;
      background: url(../img/recruit/line_first.png) no-repeat;
      a {
        padding-left: 30px;
      }
      &:hover {
        &:before {
          display: none;
        }
      }
    }
    &:last-child {
      &:after {
        right: 18px;
        top: 5px;
        display: block;
        content: " ";
        width: 40px;
        height: 80px;
        background: #fff;
        transform: rotate(-30deg);
        box-shadow: 1px 1px 2px #ccc;
        z-index: 4;
      }
      a {
        width: calc(100% - 40px);
      }
      &:hover {
        &:after {
          background: #82e7ad;
        }
      }
    }
  }
  &-detail {
    position: relative;
    padding: 60px;
    background: #fff;
    box-shadow: 0 0 3px #ccc;
    z-index: 5;
    display: none;
    &.show {
      display: block;
    }
  }
@include media(sp) {
  display: block;
  margin-bottom: 1em;
  li {
    width: auto;
    font-size: rem(16);
    box-shadow: 0px 0px 3px #ccc;
    &:after {
      display: none;
    }
    br {
      display: none;
    }
    a {
      display: block;
      height: auto;
      padding: 15px;
      box-shadow: 0 0 0;
      &:before {
        font-family: FontAwesome;
        content: "\f0d7";
        margin-right: 10px;
      }
      &.active {
        font-size: rem(16);
        //background: #82e7ad;
        //color: #fff;
      }
    }
    &:hover {
      a {
        background: #fff;
        color: #82e7ad;
        font-size: rem(16);
      }
      &:before {
        display: none;
      }
    }
    &:first-child {
      margin: 0;
      padding: 0;
      background: none;
      a {
        padding-left: 15px;
      }
    }
    &:last-child {
      a {
        width: auto;
      }
      &:after {
        display: none;
      }
    }
  }
  &-detail {
    padding: 15px;
  }
}
}

