@charset "utf-8";

/* ----------------------------------------------------------------
   component/link
/  --------------------------------------------------------------*/

.link {
  &:after {
    display: inline-block;
    content: " ";
    width: 20px;
    height: 12px;
    margin-left: 5px;
    margin-top: -1px;
    background: url(../img/share/icon_link.png) no-repeat;
    background-size: contain;
    vertical-align: middle;
  }
@include media(sp) {
  &:after {
    width: 10px;
    height: 6px;
  }
}
}

