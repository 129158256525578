@charset "utf-8";

/* ----------------------------------------------------------------
   component/ttl
/  --------------------------------------------------------------*/


.ttl-l2 {
  margin: 70px 0;
  font-size: rem(26);
  text-align: center;
  span {
    display: inline-block;
    padding: 0 70px 5px;
    border-bottom: 5px solid #0071b9;
  }
  &.-ls {
    letter-spacing: 1em;
    padding-left: 1em;
  }
  &-2 {
    margin: 0 auto 50px;
    font-size: rem(26);
    text-align: center;
    span {
      display: inline-block;
      min-width: 270px;
      padding-bottom: 5px;
      border-bottom: 5px solid #84e9af;
    }
  }
@include media(sp) {
  margin: 40px 0;
  font-size: rem(24);
  span {
    padding: 0 40px 5px;
  }
  &-2 {
    margin-bottom: 20px;
    font-size: rem(24);
  }
}
}

.section-title {
  position: relative;
  margin: 0 0 40px;
  padding: 15px;
  padding-bottom: 40px;
  font-size: rem(30);
  color: #53d1ed;
  text-align: center;
  border-top: 3px solid #53d1ed;
  background: url(../img/share/line_title.png) center bottom no-repeat;
@include media(sp) {
  margin-bottom: 20px;
  font-size: rem(22);
}
}

