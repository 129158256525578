@charset "utf-8";

/* ----------------------------------------------------------------
   theme/main_visual
/  --------------------------------------------------------------*/

.main-visual {
  .swiper {
    &-container {
      margin-bottom: 50px;
    }
    &-wrapper {
      
    }
    &-slide {
      text-align: center;
    }
    // thumbnail
    &-pagination-thumbs {
      text-align: center;
    }
    &-pagination-thumb {
      display: inline-block;
      width: 130px;
      height: 72px;
      margin-right: 12px;
      border-radius: 5px;
    }
  }

@include media(sp) {
  .swiper {
    &-container {
      margin-bottom: 10px;
    }
    &-pagination-thumbs {
      display: flex;
      justify-content: center;
      display: none;
    }
    &-pagination-thumb {
      width: 23%;
      height: 45px;
      margin-right: 5px;
      background-size: contain;
      &:last-child {
        margin: 0;
      }
    }
  }
}
}