@charset "utf-8";

/* ----------------------------------------------------------------
   theme/contact
/  --------------------------------------------------------------*/


.page-header {
  &.-contact {
    margin-bottom: 150px;
    background-image: url(../img/contact/title.jpg);
    div {
      padding-left: 60px;
      padding-right: 60px;
      &:after {
        top: 24px;
        right: 24px;
        width: 33px;
        height: 29px;
        background: url(../img/contact/img_title.png) no-repeat;
      }
    }
  }
@include media(sp) {
  &.-contact {
    margin-bottom: 120px;
    div {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}
}

.contact {
  &-description {
    @extend .description;
    margin-bottom: 50px;
    a {
      color: #0000ff;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &-section {
    &-header {
      display: flex;
      justify-content: space-between;
      margin: 0 50px 50px;
      &__title {
        margin: 0 0 10px;
        font-weight: bold;
        text-align: left;
        font-size: rem(30);
      }
      &-table {
        width: 100%;
        th,td {
          padding-bottom: 10px;
          font-weight: normal;
          text-align: left;
        }
        th {
          width: 8em;
        }
      }
      &__img {
        flex-shrink: 0;
      }
    }
    &-table {
      display: flex;
      justify-content: center;
      margin: 0 100px 50px;
      table {
        width: 100%;
      }
      th,td {
        padding-bottom: 15px;
        text-align: left;
        vertical-align: top;
      }
      th {
        width: 22%;
        font-size: rem(23);
        a {
          display: inline-block;
          &:before {
            display: inline-block;
            content: "";
            width: 18px;
            height: 18px;
            margin-right: .5em;
            background: url(../img/contact/icon_link.png) no-repeat;
            vertial-align: middle;
          }
        }
      }
      td {
        width: 28%;
        font-size: rem(17);
      }
    }
  }
@include media(sp) {
  &-section {
    &-header {
      justify-content: center;
      flex-wrap: wrap;
      margin: 0 0 30px;
      &__title {
        font-size: rem(22);
      }
      &-table {
        width: 80%;
        margin-bottom: 20px;
      }
    }
    &-table {
      margin: 0 0 50px;
      table {
        width: 93%;
      }
      tr {
        display: flex;
        flex-wrap: wrap;
      }
      th,td {
        
      }
      th {
        width: 38%;
        font-size: rem(18);
      }
      td {
        width: 60%;
        font-size: rem(16);
      }
    }
  }
}

}

.-link-tel,
.-link-mail {
  display: inline-block;
  &:before {
    display: inline-block;
    content: "";
    width: 22px;
    height: 25px;
    margin-right: .2em;
    background: url(../img/contact/icon_tel.png) no-repeat;
    vertical-align: middle;
  }
}

.-link-mail {
  &:before {
    width: 22px;
    height: 18px;
    background: url(../img/contact/icon_mail.png) no-repeat;
  }
}
