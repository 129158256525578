@charset "utf-8";

/* ----------------------------------------------------------------
   thema/form
/  --------------------------------------------------------------*/

.form {
  width: 100%;
  margin-bottom: 120px;
  border-top: 1px solid #000;
  border-collapse: collapse;
  border-spacing: 0;
  &-line {
    display: flex;
    align-items: center;
    margin-bottom: .5em;
  }
  &-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    li {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      margin-right: 2em;
    }
    &.-col2 {
      li {
        width: 50%;
        margin-right: 0;
      }
    }
  }
  &-box {
    display: flex;
    justify-content: space-between;
  }
  &-select-service,
  &-select-service-detail {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      height: 44px;
      margin-bottom: 10px;
    }
  }
  &-select-service {
    width: 45%;
    //padding-right: 1em;
    label {
      margin-right: 0 !important;
    }
    li {
      display: flex;
      align-items: center;
    }
    &-detail {
      width: 55%;
    }
  }
  th,td {
    padding: 23px 0;
    border-bottom: 1px solid #000;
  }
  th {
    width: 30%;
    vertical-align: top;
    text-align: left;
    .required {
      color: #be0000;
    }
  }
@include media(sp) {
  margin-bottom: 60px;
  &-list {
    &.-col2 {
      display: block;
      li {
        width: auto;
      }
    }
  }
  th,td {
    display: block;
    padding: 15px 0;
  }
  th {
    width: 100%;
    //padding: 0 0 10px;
  }
}
}

.form-item-select {
  position: relative;
  display: inline-block;
  margin-bottom: 1em;
  padding-right: 30px;
  background: #e4e4e4;
  border-radius: 6px;
  &:last-child {
    margin: 0;
  }
  &:after {
    position: absolute;
    right: 10px;
    top: 22%;
    font-family: FontAwesome;
    content: "\f0d7";
    font-size: 25px;
    line-height: 1;
  }
  select {
    outline:none;
    text-indent: 0.01px;
    text-overflow: '';
    background: none transparent;
    vertical-align: middle;
    font-size: inherit;
    color: inherit;
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    border: 0;
    width: 100%;
    padding: 15px 20px;
    box-sizing: border-box;
    line-height: 1;
  }
}

.form-item-text {
  width: 80%;
  box-sizing: border-box;
  height: 44px;
  padding: 6px 8px;
  background: #e4e4e4;
  border: 0;
  border-radius: 6px;
  &:focus {
    outline: 0;
  }
  &.w-1 {
    width: 45px;
  }
  &.w-2 {
    width:100px;
  }
  &.w-2-2 {
    width:110px;
  }
  &.w-3 {
    width: 130px;
  }
  &.w-4 {
    width: 60%;
  }
@include media(sp) {
  width: 100%;
  height: 2.8em;
  &.w-1 {
    width: 2.8em;
  }
  &.w-2 {
    width:100px;
  }
  &.w-2-2 {
    width:110px;
  }
  &.w-3 {
    width: 4.5em;
  }
  &.w-4 {
    width: 67%;
  }
}

}

.form-item-radio {
  display: none;
  & + label {
    display: block;
    padding-left: 30px;
    position:relative;
    margin-right: 50px;
  }
  & + label::before{
    content: " ";
    display: block;
    position: absolute;
    top: .05em;
    left: 0;
    width: 20px;
    height: 20px;
    background: #e4e4e4;
    border-radius: 50%;
  }
  &:checked + label::after{
    content: "";
    display: block;
    position: absolute;
    top: 3px;
    left: 3px;
    width: 14px;
    height: 14px;
    background: #999;
    border-radius: 50%;
  }
}

.form-item-textarea {
  width: 80%;
  box-sizing: border-box;
  padding: 6px 8px;
  background: #e4e4e4;
  resize: vertical;
  border-radius: 6px;
  border: 0;
  &:focus {
    outline: 0;
  }
@include media(sp) {
  width: 100%;
}
}

.form-btn {
  text-align: center;
  &-submit {
    margin: 50px auto 0;
    padding: 15px 80px;
    font-size: rem(21);
    border: 3px solid #82e7ad;
    background: #fff;
    text-align: center;
    color: #82e7ad;
  }
@include media(sp) {
  &-submit {
    padding: 15px 40px;
    min-width: 260px;
    box-sizing: border-box;
  }
}
}

.error-list {
  list-style: none;
  margin: 0 0 2em;
  padding: 0;
  li {
    &:before {
      content: "※";
      color: #be0000;
    }
  }
}

.confirm-text {
  font-size: rem(24);
}

