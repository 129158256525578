@charset "utf-8" ;

/* ----------------------------------------------------------------
   utility/text
/  --------------------------------------------------------------*/

.u-text-strong {
  font-weight: bold;
}

.u-text-transform.--upper {
  text-transform: uppercase;
}

.u-text-post-positional-particle {
  font-size: 72.72727273%; // 16px in case of parent elements font-size: 22px;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

.u-text-center {
  text-align: center;
}

.u-mb-0 {
  margin-bottom: 0 !important;
}

.text-note {
  font-size: rem(14);
  &--small {
    font-size: rem(12);
  }
@include media(sp) {
  font-size: rem(12);
  &--small {
    font-size: rem(10);
  }
}
}

.description {
  margin-bottom: 75px;
  text-align: center;
@include media(sp) {
  margin-bottom: 30px;
}
}