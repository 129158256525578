@charset "utf-8";

/*----------------------------------------------------
	side
----------------------------------------------------*/
.side {
  width: 250px;
  flex-shrink: 0;
  flex-grow: 0;
  margin-top: -50px;
  &-category {
    position: relative;
    display: block;
    margin-bottom: 20px;
    border: 2px solid #f8a5a5;
    border-radius: 7px;
    font-size: rem(18);
    background: #fff;
    &:after {
      position: absolute;
      right: 20px;
      top: 22%;
      font-family: FontAwesome;
      content: "\f0d7";
      font-size: 25px;
      line-height: 1;
    }
    &-select {
      outline:none;
      text-indent: 0.01px;
      text-overflow: '';
      background: none transparent;
      vertical-align: middle;
      font-size: inherit;
      color: inherit;
      -webkit-appearance: button;
      -moz-appearance: button;
      appearance: button;
      border: 0;
      width: 100%;
      padding: 15px 20px;
      box-sizing: border-box;
      line-height: 1;
    }
  }
  &-box {
    margin-bottom: 24px;
    padding: 20px;
    box-shadow:0px 0px 4px 0px #bbb;
    &__title {
      margin: 0 0 15px;
      padding-left: 15px;
      font-size: rem(18);
      border-left: 7px solid #f8a5a5;
      line-height: 1;
    }
    &-list {
      list-style: none;
      margin: 0 0 20px;
      padding: 0 0 15px;
      border-bottom: 1px dotted #f8a5a5;
      &:last-child {
        margin: 0;
        padding: 0;
        border: 0;
      }
      a {
        position: relative;
        display: inline-block;
        padding-left: 26px;
        font-size: rem(14);
        &:before {
          position: absolute;
          display: inline-block;
          content: " ";
          left: 0;
          top: .3em;
          width: 11px;
          height: 11px;
          margin-right: 15px;
          background: url(../img/share/icon_side_link.png) no-repeat;
        }
      }
    }
  }
  &-bnr {
    list-style: none;
    margin: 0 5px;
    padding: 0;
    li {
      margin-bottom: 15px;
    }
    &-item {
      &__link {
        display: block;
        width: auto;
      }
    }
  }
@include media(sp) {
  width: auto;
  margin-top: 50px;
  &-box {
    &-list {
      li {
        margin-bottom: 10px;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

}
