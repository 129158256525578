@charset "utf-8";

/* ----------------------------------------------------------------
   component/media
/  --------------------------------------------------------------*/

.hidden {
  display: none;
}

.media-section {
  //overflow: hidden;
  &__detail {
  }
}

