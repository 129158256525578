@charset "utf-8";

/* ----------------------------------------------------------------
   theme/u_car
/  --------------------------------------------------------------*/

.page-header {
  &.-u-car {
    background-image: url(../img/u-car/title.jpg);
    div {
      &:after {
        width: 47px;
        height: 19px;
        background: url(../img/u-car/img_title.png) no-repeat;
      }
    }
  }
}

.ucar {
  &-search {
    &__title {
      @extend %font-notosans;
      margin: 0 0 70px;
      font-size: rem(26);
      text-align: center;
      img {
        margin-top: 20px;
      }
    }
    &-list {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      width: 860px;
      margin: 40px auto 100px;
      &__item {
        margin-bottom: 15px;
        font-size: rem(14);
        @extend %font-notosans;
        a {
          position: relative;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          width: 100%;
          padding: 20px 30px;
          line-height: 1;
          background: #f2f2f2;
          border-radius: 10px;
          box-sizing: border-box;
          img {
            margin-right: 30px;
          }
          &:after {
            position: absolute;
            display: inline-block;
            content: " ";
            top: 15px;
            right: 60px;
            width: 24px;
            height: 24px;
            background: url(../img/u-car/icon_search.png) no-repeat;
            background-size: contain;
          }
        }
        &.-gazoo {
          width: 63%;
          font-size: rem(20);
          a {
            flex-wrap: wrap;
            justify-content: center;
            height: 100%;
            img {
              margin: 0;
            }
            &:after {
              top: auto;
              bottom: 2em;
              right: 30px;
              width: 40px;
              height: 40px;
            }
          }
          span {
            display: block;
            width: 100%;
            text-align: right;
          }
        }
      }
      div {
        //width: 50%;
        .ucar-search-list__item {
          width: 100%;
        }
      }
    }
  }
@include media(sp) {
  &-search {
    &__title {
      margin-bottom: 40px;
      img {
        width: 215.5px;
      }
    }
    &-list {
      display: block;
      width: auto;
      margin: 0 30px 40px;
      &__item {
        a {
          justify-content: center;
          padding: 20px 10px;
          img {
            margin-right: 10px;
          }
          &:after {
            top: 5px;
            right: 10px;
          }
        }
        &.-gazoo {
          width: auto;
          font-size: rem(16);
          a {
            img {
              margin-bottom: 10px;
            }
            &:after {
              width: 20px;
              height: 20px;
              right: 7em;
              bottom: 15px;
            }
          }
        }
      }
    }
  }
}
  &-brand {
    text-align: center;
    &__title {
      margin-bottom: 50px;
      font-size: rem(26);
      @extend %font-notosans;
      img {
        margin-top: 10px;
      }
    }
    &__text {
      margin-bottom: 50px;
    }
  }
  &-shop {
    margin-bottom: 80px;
  }
  &-shop-section {
    display: flex;
    //justify-content: center;
    &.-shop01 {
      background: #f8a5a5;
    }
    &.-shop02 {
      background: #53d1ed;
    }
    &-detail {
      position: relative;
      //display: flex;
      //align-items: center;
      //width: 38%;
      width: 50%;
      padding: 50px 0;
      &:before {
        position: absolute;
        display: block;
        content: " ";
        top: 50%;
        right: -34px;
        width: 68px;
        height: 68px;
        margin-top: -34px;
        transform: rotate(-45deg);
        z-index: 1;
        background: #f8a5a5;
      }
      &-inner {
        width: 420px;
        width: 60%;
        margin: 0 auto;
      }
      &.-shop02 {
        &:before {
          background: #53d1ed;
        }
      }
    }
    &__title {
      margin: 0 0 40px;
      @extend %font-notosans;
      font-size: rem(28);
      text-align: center;
    }
    &__headline {
      margin: 0 0 10px;
      font-size: rem(24);
      color: #fff;
      border-bottom: 1px solid #000;
      text-align: center;
    }
    &__text {
      margin-bottom: 20px;
      &:last-child {
        margin: 0;
      }
    }
    &__img {
      width: 50%;
      display: flex;
      background: url(../img/u-car/img01.jpg) center center no-repeat;
      background-size: cover;
      div {
        height: 100%;
      }
      img {
        display: none;
      }
      &.-shop02 {
        background-image: url(../img/u-car/img02.jpg);
      }
    }
    &__btn {
      margin-bottom: 60px;
      text-align: center;
      a {
        display: inline-block;
        padding: 10px 20px;
        background: #fff;
        border-radius: 6px;
        background-repeat: no-repeat;
        background-position: 15px center;
        font-weight: bold;
      }
      &.-googlemap a {
        padding-left: 50px;
        background-image: url(../img/u-car/icon_googlemap.png);
      }
      &.-shoplist a {
        padding-left: 80px;
        background-image: url(../img/share/h_icon05.png);
        background-size: 40px auto;
      }
    }
    &:nth-child(odd) {
      .ucar-shop-section-detail {
        order: 1;
        &:before {
          right: auto;
          left: -34px;
        }
      }
      .ucar-shop-section__img {
        order: 0;
      }
    }
  }
  &-btn-contact {
    margin: 35px 0 110px;
    text-align: center;
    a {
      display: inline-block;
      padding: 20px 60px;
      border: 3px solid #84e9af;
      background: #fff;
      color: #84e9af;
      font-size: rem(21);
      letter-spacing: 1.75px;
    }
  }

@media screen and (max-width: 1600px) {
  /*&-shop-section {
    align-items: center;
    &-detail {
      width: 50%;
      padding: 40px 20px;
      box-sizing: border-box;
    }
    &__img {
      width: 50%;
    }
  }*/
}
@include media(tab) {
  &-shop-section {
    display: block;
    &-detail {
      width: auto;
      padding: 40px 20px 0;
      &:before {
        display: none;
      }
      &-inner {
        width: 80%;
      }
    }
    &__title {
      margin-bottom: 20px;
      font-size: rem(24);
    }
    &__headline {
      font-size: rem(20);
    }
    &__text {
      margin-bottom: 30px;
    }
    &__img {
      width: auto;
      height: 550px;
    }
  }
  &-btn-contact {
    margin-bottom: 50px;
    a {
      padding: 20px;
    }
  }
}
@include media(sp) {
  &-shop-section {
    &-detail {
      &-inner {
        width: 100%;
      }
    }
    &__img {
      height: 300px;
    }
  }
}

}
