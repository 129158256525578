@charset "utf-8";

/* ----------------------------------------------------------------
   theme/service
/  --------------------------------------------------------------*/

.page-header {
  &.-service {
    background-image: url(../img/service/title.jpg);
    div {
      &:after {
        top: 20px;
        right: 20px;
        width: 37px;
        height: 26px;
        background: url(../img/service/img_title.png) no-repeat;
      }
    }
  }
}
.service {
  &-index {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 50px 60px 0;
    &-item {
      position: relative;
      width: 415px;
      margin: 0 0 45px;
      border-radius: 28px;
      overflow: hidden;
      a {
        display: block;
        &:hover {
          opacity: 1;
        }
      }
      &-title {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        height: 300px;
        margin: 0;
        padding: 0 15px 10px 35px;
        font-size: rem(36);
        font-weight: bold;
        line-height: 1.2;
        text-shadow: 1px 1px 3px #231815;
        color: #fff;
        @extend %font-notosans;
      }
      &__text {
        position: relative;
        margin: 0;
        padding: 30px 35px;
        background: #f0f0f0;
        &:after {
          position: absolute;
          display: block;
          content: " ";
          right: 18px;
          top: 50%;
          margin-top: -25px;
          width: 46px;
          height: 50px;
          background: url(../img/service/index_link.png) no-repeat;
        }
      }
      &:hover {
        .service-index-item__text {
          background: #00aee6; /* Old browsers */
          background: radial-gradient(ellipse at center,  #00aee6 1%,#53d1ed 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00aee6', endColorstr='#53d1ed',GradientType=1 );
          color: #fff;
          &:after {
            background: url(../img/service/index_link_over.png) no-repeat;
          }
        }
      }
      &.-inspection {
        background: url(../img/service/index_img_inspection.png) no-repeat;
        .service-index-item-title {
          letter-spacing: .5em;
        }
      }
      &.-maintenance {
        background: url(../img/service/index_img_maintenance.png) no-repeat;
        .service-index-item-title {
          letter-spacing: .5em;
        }
      }
      &.-originalservice {
        background: #b6e6ca url(../img/service/index_img_originalservice.png) center 20% no-repeat;
        .service-index-item-title {
          letter-spacing: -.09em;
        }
      }
      &.-accessories {
        background: url(../img/service/index_img_accessories.png) no-repeat;
        .service-index-item-title {
          letter-spacing: -.09em;
        }
      }
    }
  }
@include media(sp) {
  &-index {
    display: block;
    margin: 30px 0 0;
    &-item {
      width: auto;
      border-radius: 14px;
      &:last-child {
        margin-bottom: 0;
      }
      &-title {
        flex-wrap: wrap;
        height: 200px;
        font-size: rem(30);
        padding: 0 10px 10px;
        img {
          margin-bottom: -.4em;
        }
      }
      &__text {
        padding: 20px 15px;
        padding-right: 50px;
        &:after {
          width: 24px;
          height: 25px;
          margin-top: -12px;
          background-size: contain;
        }
      }
      &.-inspection {
        background-size: contain;
      }
      &.-maintenance {
        background-size: contain;
      }
      &.-originalservice {
        
      }
      &.-accessories {
        background-size: contain;
        .service-index-item-title {
          img {
            margin: 0;
          }
        }
      }
    }
  }

}

  // 個別
  &-detail {
    &-header {
      display: flex;
      align-items: center;
      margin: 0 0 50px;
      padding-top: 20px;
      &-text {
        order: 2;
        text-align: center;
      }
      &__img {
        order: 1;
        margin: 0;
        margin-right: 50px;
      }
      &__title {
        margin: 0 0 20px;
        font-size: rem(36);
        color: #53d1ed;
      }
    }
@include media(sp) {
    &-header {
      display: block;
      &__title {
        font-size: rem(30);
      }
      &__img {
        margin: 20px 0 0;
      }
    }
}
    &-section {
      margin-bottom: 80px;
      &:last-child {
        margin-bottom: 0;
      }
      &__title {
        position: relative;
        margin: 0 0 40px;
        padding: 15px;
        padding-bottom: 40px;
        font-size: rem(30);
        color: #53d1ed;
        text-align: center;
        border-top: 3px solid #53d1ed;
        //border-bottom: 3px solid #53d1ed;
        //background: #fff;
        background: url(../img/service/line_title.png) center bottom no-repeat;
      }
      &.-column {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .service-detail-section-item {
          width: 48%;
          &-inner {
            display: flex;
            flex-direction: column;
            height: 100%;
          }
        }
        .service-box {
          height: 100%;
          margin: 0 0 75px;
          box-sizing: border-box;
        }
      }
    }
@include media(sp) {
    &-section {
      margin-bottom: 40px;
      &__title {
        margin-bottom: 20px;
        font-size: rem(22);
      }
      &.-column {
        display: block;
        .service-detail-section-item {
          width: auto;
          margin-bottom: 40px;
        }
        .service-box {
          margin-bottom: 0;
        }
      }
    }
}
  }
  &-btn {
    list-style: none;
    margin: 30px 90px;
    padding: 0;
    &-item {
      margin-bottom: 25px;
    }
    a {
      position: relative;
      display: block;
      padding: 28px 20px;
      background: #00aee6; /* Old browsers */
      background: radial-gradient(ellipse at center,  #00aee6 1%,#53d1ed 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00aee6', endColorstr='#53d1ed',GradientType=1 );
      color: #fff;
      font-size: rem(22);
      text-align: center;
      line-height: 1;
      &:before {
        position: absolute;
        content: " ";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-repeat: no-repeat;
        background-position: left bottom;
      }
      &:after {
        position: absolute;
        right: 20px;
        top: 50%;
        margin-top: -.5em;
        font-family: FontAwesome;
        content: "\f0da";
      }
    }
    .-service {
        a {
          text-align: right;
          padding-right: 40px;
          &:before {
            background-image: url(../img/service/icon01.png);
          }
        }
    }
    .-technology {
        a {
          text-align: right;
          padding-right: 40px;
          &:before {
            background-image: url(../img/service/icon02.png);
          }
        }
    }
    .-tscubic {
        a {
          padding: 15px 20px;
          padding-left: 50px;
          &:before {
            background-image: url(../img/service/icon03.png);
            background-position: 7px center;
          }
       }
    }
    .-tsukushi {
        a {
          padding: 25px 20px;
          padding-left: 50px;
          &:before {
            background-image: url(../img/service/icon04.png);
            background-position: 7px center;
          }
        }
    }
    .-toyota {
      a {
        padding: 25px 20px;
        padding-left: 70px;
        font-size: rem(19);
        &:before {
          background-image: url(../img/service/logo_toyota.png);
          background-position: 15px center;
        }
        &:after {
          display: none;
        }
      }
    }
    &.-detail {
      margin: 20px 0;
      text-align: center;
      a {
        display: inline-block;
        padding: 25px 70px;
        &:after {
          display: none;
        }
      }
    }
    &.-column {
      display: flex;
      justify-content: space-between;
      width: 670px;
      margin: 0 auto 35px;
      li {
        width: 48%;
      }
    }
  }
@include media(sp) {
  &-btn {
    margin: 30px 0;
    li {
      margin-bottom: 10px;
    }
    a {
      font-size: rem(18);
    }
    &.-column {
      display: block;
      width: auto;
      margin: 0 0 35px;
      li {
        width: auto;
      }
    }
  }
}
  &-shop-link {
    width: 670px;
    margin: 60px auto;
    border: 1px solid #53d1ed;
    background: #fff;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
      font-size: rem(21);
      text-align: center;
      &:before {
        display: inline-block;
        content: " ";
        width: 47px;
        height: 23px;
        margin-right: 30px;
        background: url(../img/service/icon_shop.png) no-repeat;
      }
      &:after {
        display: inline-block;
        content: " ";
        width: 31px;
        height: 31px;
        margin-left: 50px;
        background: url(../img/service/icon_link.png) no-repeat;
      }
    }
  }

@include media(sp) {
  &-shop-link {
    width: auto;
    margin: 0 0 20px;
  }
}

  &-box {
    margin: 0 25px;
    padding: 30px;
    background: #fff;
    box-shadow: 0 0 3px #ccc;
    .att-list.-hr {
      padding: 10px 0;
      border-top: 1px solid #c2c2c2;
      border-bottom: 1px solid #c2c2c2;
    }
    &__title {
      margin: 0 0 20px;
      font-size: rem(24);
      color: #53d1ed;
      text-align: center;
    }
    &-media {
      display: flex;
      align-items: center;
      margin-bottom: 60px;
      &__img {
        //width: 433px;
        flex-grow: 0;
        flex-shrink: 0;
        margin-right: 40px;
        order: 1;
      }
      &-text {
        order: 2;
        text-align: center;
      }
      &__title {
        margin: 0 0 20px;
        font-size: rem(28);
        color: #53d1ed;
        text-align: center;
      }
      &-link {
        list-style: none;
        margin: 0;
        padding: 0;
        &__item {
          margin-bottom: 20px;
          a {
            display: block;
            text-align: left;
          }
          span {
            display: inline-block;
            margin-bottom: 5px;
            padding-left: 25px;
            background: url(../img/service/icon_link.png) no-repeat;
            font-size: rem(21);
            font-weight: bold;
          }
        }
      }
    }
    &__img {
      text-align: center;
    }
  }
@include media(sp) {
  &-box {
    margin: 0 10px;
    padding: 15px;
    &-media {
      display: block;
      margin-bottom: 20px;
      &__img {
        width: auto;
        margin-right: 0;
      }
      &__title {
        font-size: rem(20);
      }
      &-link {
        &__item {
          span {
            padding-left: 25px;
            background-size: 20px;
            font-size: rem(16);
          }
        }
      }
    }
  }
}
  &-headline {
    margin: 0 0 15px;
    font-size: rem(21);
  }

  &-table {
    table {
      //table-layout: fixed;
      width: 100%;
      margin-bottom: 25px;
      border-collapse: collapse;
      border-spacing: 0;
      border: 3px solid #f8a5a5;
    }
    th,td {
      padding: 10px;
      border-bottom: 1px solid #f8a5a5;
      border-right: 1px solid #f8a5a5;
      font-weight: normal;
      text-align: center;
    }
    th {
      background: rgba(248,165,165,.2);
      line-height: 1.2;
      &.-total {
        background: #edfcfc;
      }
    }
    .-basic {
      background: rgba(248,206,165,.2);
    }
    td {
      &.-total {
        background: #edfcfc;
      }
    }
    &-type01 {
      th,td {
        padding: 5px;
        font-size: rem(12);
      }
      td {
        width: 9%;
        &.-type {
          //width: 5em;
        }
        &.-car {
          width: 19%;
          text-align: left;
        }
      }
    }
    &-type02 {
      table-layout: fixed;
    }
  }
@include media(sp) {
  &-table {
    overflow: auto;
    table {
      width: 890px;
    }
  }
}

}

