@charset "utf-8";
/* -----------------------------------------------------------
	l-header
----------------------------------------------------------- */
/* 
	for pc
----------------------------------------------------------- */

.l-header {
  position: relative;
  padding: 10px 0;
  line-height: 1;
  background: #fff;
  z-index: 10;
  &.is-fixed {
    position: fixed;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    background: #fff !important;
  }
  &-inner {
    position: relative;
    display: flex;
    margin: 0 auto;
    padding: 0;
    justify-content: space-between;
    align-items: center;
  }
  
  // logo
  &-logo {
    //display: flex;
    //align-items: center;
    padding: 0 20px;
    line-height: 0;
    &__img {
      margin: 0;
      //margin-right: 1em;
      .icon {
        display: inline-block;
        width: 37px;
        height: 26px;
        margin-right: 15px;
        vertical-align: middle;
        background: url(../img/share/l_img.png) no-repeat;
      }
    }
    &__text {
      margin: 0;
    }
  }
  
  // menu area
  &-menu {
    display: flex;
    align-items: center;
  }
  
  // nav
  &-nav {
    display: flex;
    &__title {
      display: none;
    }
    &-list {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      &-utility {
        display: none;
      }
      &__item {
        width: 5em;
        position: relative;
        border-right: 1px solid #c7c7c7;
        &:first-child {
          border-left: 1px solid #c7c7c7;
        }
        a {
          display: block;
          width: 100%;
          transition: .5s;
          font-size: rem(11);
          color: #000;
          text-align: center;
        }
        &.-lineup a {
          //letter-spacing: 1em;
          //text-indent: 1em;
        }
        .icon {
          display: block;
          height: 45px;
          background-repeat: no-repeat;
          background-position: center;
        }
        .-info {
            background-image: url(../img/share/h_icon01.png);
            background-size: 35px auto;
        }
        .-lineup {
            background-image: url(../img/share/h_icon02.png);
            background-size: 55px auto;
        }
        .-u-car {
            background-image: url(../img/share/h_icon03.png);
            background-size: 46px auto;
            background-position: center 18px;
        }
        .-service {
            background-image: url(../img/share/h_icon04.png);
            background-size: 37.5px auto;
        }
        .-shop {
            background-image: url(../img/share/h_icon05.png);
            background-size: 46.5px auto;
        }
        .-contact {
            background-image: url(../img/share/h_icon06.png);
            background-size: 32.5px auto;
        }
        .-ownership {
            background-image: url(../img/share/h_icon07.png);
            background-size: 42px auto;
        }
        &--sub {
          display: none;
        }
      }
      &-utility {
        display: none;
      }
    }
  }
  
  // search
  &-search {
    position: relative;
    padding: 0 20px;
    text-align: center;
    &__form {
      display: none;
      position: absolute;
      bottom: -92px;
      right: 0;
    }
    #sitesearch {
      display: flex;
      align-items: center;
      padding: 10px;
      background: #c7c7c7;
    }
    &__area {
      width: 24.5em;
      height: 3em;
      margin-right: 10px;
      padding: 5px;
      border: 0;
      color: #A9A9AA;
      font-size: rem(16);
      background: #fff;
    }
    &__submit {
      width: 27px;
      height: 27px;
      background: url(../img/share/h_icon_search.png) no-repeat;
      font-size: 0;
      border: 0;
    }
    &__btn {
      display: block;
      width: 27px;
      height: 27px;
      background: url(../img/share/h_icon_search.png) no-repeat;
      font-size: 0;
      border: 0;
    }
  }
  &-sns {
    display: none;
  }
  .menu-trigger {
    display: none;
  }
}


/* 
	for sp
----------------------------------------------------------- */
@include media(sp) {
.l-header {
  position: relative;
  padding: 15px;
  z-index: 102;
  // logo
  &-logo {
    margin: 0;
    padding: 0;
    &__img {
      margin: 0;
      line-height: 0;
      width: 175px;
    }
  }
  .menu-trigger {
    display: block;
    width: 25px;
    height: 18px;
    background: url(../img/share/icon_menu.png) no-repeat;
    background-size: contain;
    &.open {
      background-image: url(../img/share/icon_close.png);
    }
  }
  // menu
  &-menu {
    position: fixed;
    display: none;
    width: 100%;
    top: 48px;
    height: calc(100% - 48px);
    overflow: auto;
    left: 0;
    margin: 0;
    padding: 0 7px;
    overflow: auto;
    box-sizing: border-box;
    background: #fff;
    z-index: 1000;
    -webkit-backface-visibility: hidden;
  }
  // nav
  &-nav {
    display: block;
    &-list {
      display: block;
      &__item {
        width: auto;
        border: 0;
        border-bottom: 1px solid #a9a9a9;
        &:first-child {
          border-left: 0;
        }
        a {
          position: relative;
          display: flex;
          align-items: center;
          font-size: rem(14);
          padding-left: 6%;
          box-sizing: border-box;
          &:after {
            position: absolute;
            right: 6%;
            display: inline-block;
            content: "";
            width: 28px;
            height: 28px;
            background: url(../img/share/icon_link01.png) no-repeat;
            background-size:contain;
          }
        }
        .text {
          display: inline-block;
        }
        .icon {
          width: 38.5px;
          height: 45px;
          margin-right: 15px;
        }
        .-info,
        .-lineup,
        .-u-car,
        .-shop,
        .-ownership {
            background-size: 36.5px auto;
        }
        .-u-car {
            background-size: 36.5px auto;
            background-position: center;
        }
        .-service {
            background-size: 37.5px auto;
        }
        .-contact {
            background-size: 33.5px auto;
        }
        &.-lineup a:after {
          background-image: url(../img/share/icon_link02.png);
        }
        &.-u-car a:after {
          background-image: url(../img/share/icon_link03.png);
        }
        &.-service a:after {
          background-image: url(../img/share/icon_link_toggle01.png);
        }
        &.-service.open a:after {
          background-image: url(../img/share/icon_link_toggle01_02.png);
          background-position: center center;
        }
        &.-shop a:after {
          background-image: url(../img/share/icon_link_toggle02.png);
        }
        &.-shop.open a:after {
          background-image: url(../img/share/icon_link_toggle02_02.png);
          background-position: center center;
        }
        &.-contact a:after {
          background-image: url(../img/share/icon_link04.png);
        }
        &.-ownership a:after {
          background-image: url(../img/share/icon_link05.png);
        }
        &--sub {
          display: none;
          list-style: none;
          margin: 0;
          margin-left: 6%;
          padding: 0;
          li {
            border-top: 1px solid #d37baf;
            a {
              padding-left: 0;
              &:after {
                top: .7em;
                background-image: url(../img/share/icon_link_sub01.png) !important;
                background-size: 26.5px auto;
              }
            }
            .-inspection {
              background-image: url(../img/share/h_icon04_01.png);
              background-size: 36.5px auto;
            }
            .-maintenance {
              background-image: url(../img/share/h_icon04_02.png);
              background-size: 31.5px auto;
            }
            .-accessories {
              background-image: url(../img/share/h_icon04_03.png);
              background-size: 27px auto;
            }
            .-o-service {
              background-image: url(../img/share/h_icon04_04.png);
              background-size: 30px auto;
            }
          }
        }
        &.-shop {
          .l-header-nav-list__item--sub {
            li {
              border-color: #0b7ac1;
              a:after {
                background-image: url(../img/share/icon_link_sub02.png) !important;
              }
              .-aki {
                background-image: url(https://with-sw86.movabletype.biz/shop/asset/aki.jpg);
                background-size: contain;
              }
            }
          }
        }
      }
      &-utility {
        display: block;
        list-style: none;
        margin: 0 0 12px;
        padding: 0;
        li {
          border-bottom: 1px solid #a9a9a9;
          &:last-child {
            border: 0;
          }
          a {
            display: block;
            padding: 7px;
            padding-left: 50px;
            background: url(../img/share/icon_link06.png) 24px center no-repeat;
            background-size: 12px auto;
          }
        }
      }
    }
  }
  // search
  &-search {
    padding: 0;
    &__form {
      display: block;
      position: static;
    }
    #sitesearch {
      display: flex;
      align-items: center;
      padding: 15px;
      padding-left: 24px;
      background: #e6e6e6;
    }
    &__area {
      width: 80%;
      height: 2.5em;
      margin-right: 20px;
      padding: 5px;
      border: 0;
      color: #A9A9AA;
      font-size: rem(16);
      background: #fff;
    }
    &__submit {
      width: 27px;
      height: 27px;
      background: url(../img/share/h_icon_search.png) no-repeat;
      font-size: 0;
      border: 0;
    }
    &__btn {
      display: none;
      width: 27px;
      height: 27px;
      background: url(../img/share/h_icon_search.png) no-repeat;
      font-size: 0;
      border: 0;
    }
  }
  
  
  // sns
  &-sns {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    margin: 0;
    padding: 6%;
    li {
      font-size: 0;
      margin-left: 20px;
      a {
        display: inline-block;
        width: 40px;
        height: 40px;
        background-repeat: no-repeat;
        vertical-align: middle;
      }
      .-twitter {
        background-image: url(../img/share/i_twitter.png);
      }
      .-facebook {
        background-image: url(../img/share/i_facebook.png);
      }
      .-line {
        background-image: url(../img/share/i_line.png);
      }
      .-instagram {
        background-image: url(../img/share/i_instagram.png);
      }
    }
  }
}

}
