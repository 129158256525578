@charset "utf-8" ;

/* ----------------------------------------------------------------
   layout/content
/  --------------------------------------------------------------*/

.l-content {
  background: url(../img/share/bg.png) repeat-y;
  background-size: 100% auto;
  overflow: hidden;
  &-inner {
    &.contents-wrap {
      display: flex;
      justify-content: space-between;
    }
  }
@include media(sp) {
  &-inner {
    &.contents-wrap {
      display: block;
    }
  }
}

}

