@charset "utf-8";

/* ----------------------------------------------------------------
   theme/archive
/  --------------------------------------------------------------*/

.contents-wrap {
  padding-bottom: 150px;
@include media(sp) {
  padding-bottom: 60px;
}
}

.archive {
  @extend %font-notosans;
  display: flex;
  flex-wrap: wrap;
  width: 720px;
  padding-top: 22px;
  justify-content: space-between;
  &-item {
    width: 48%;
    margin-bottom: 20px;
    //margin-right: 20px;
    padding: 20px;
    background: rgba(255,255,255, .8);
    box-shadow:0px 0px 4px 0px #bbb;
    box-sizing: border-box;
    &:nth-child(even) {
      margin-right: 0;
    }
    &-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    &__img {
      margin: 0 0 20px;
      line-height: 0;
      img {
        border-radius: 16px;
      }
    }
    &__date {
      font-size: rem(14);
      font-weight: bold;
    }
    &__category {
      @extend %archive-category;
    }
    &__text {
      margin: 0;
    }
  }

@include media(sp) {
  width: auto;
  &-item {
    width: auto;
    margin: 0 0 15px !important;
    padding: 15px;
  }
}
}

    %archive-category {
      min-width: 6em;
      padding: 5px 7px;
      line-height: 1;
      border-radius: 3px;
      text-align: center;
      font-size: rem(12);
      &.-info {
        background: #ffe68f;
      }
      &.-news {
        background: #f8a5a5;
      }
      &.-newcar {
        background: #009CC6;
      }
      &.-shop {
        background: #EC6B77;
      }
      &.-recruit {
        background: #00b900;
      }
    }

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  width: 100%;
  margin: 180px 0 0;
  padding: 0;
  li {
    margin-right: 5px;
    font-size: rem(21);
    font-weight: bold;
    span,a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      text-align: center;
      line-height: 1;
      border: 1px solid #f8a5a5;
      border-radius: 6px;
    }
    span {
      background: #f8a5a5;
      color: #000;
    }
    a {
      background: #fff;
      color: #a9a9a9;
      &:hover {
        background: #f8a5a5;
        color: #000;
      }
    }
    &.prev,
    &.next {
      margin: 0 30px;
      font-size: rem(16);
      a {
        width: auto;
        height: auto;
        padding: 0 10px 5px;
        border: 0;
        border-bottom: 2px solid #f8a5a5;
        background: none;
        border-radius: 0;
        font-weight: normal;
        &:hover {
          background: none;
          color: inherit;
        }
      }
    }
    &.prev {
      margin-left: 0;
      a {
        &:before {
          display: inline-block;
          font-family: FontAwesome;
          content: "\f0d9";
          color: #f8a5a5;
          margin-right: .5em;
          font-size: 20px;
        }
      }
    }
    &.next {
      margin-right: 0;
      a {
        &:after {
          display: inline-block;
          font-family: FontAwesome;
          content: "\f0da";
          color: #f8a5a5;
          margin-left: .5em;
          font-size: 20px;
        }
      }
    }
  }
@include media(sp) {
  flex-wrap: wrap;
  margin-top: 60px;
  li {
    margin-bottom: 5px;
    font-size: rem(14);
    span,a {
      width: 30px;
      height: 30px;
    }
    &.prev,
    &.next {
      font-size: rem(14);
      a {
        width: auto;
        height: auto;
      }
    }
    &.prev {
      margin-right: 10px;
    }
    &.next {
      margin-left: 10px;
    }
  }
}
}

