@charset "utf-8";

/* ----------------------------------------------------------------
   component/btn
/  --------------------------------------------------------------*/

.btn {
  text-align: center;
  &__link {
    @extend %btn-gradation;
    &.-tel {
      font-weight: bold;
      &:before {
        display: inline-block;
        content: " ";
        width: 30px;
        height: 24px;
        margin-right: 1em;
        background: url(../img/shop/icon_tel_fff.png) no-repeat;
      }
    }
  }
@include media(sp) {
  &__link {
    width: 100%;
  }
}
}

.btn-detail {
  text-align: center;
  &__link {
  @extend %btn-gradation;
      &:after {
        position: absolute;
        right: 20px;
        top: 50%;
        margin-top: -.5em;
        font-family: FontAwesome;
        content: "\f0da";
      }
  }
@include media(sp) {

}
}

.btn-contact {
  margin: 35px auto;
  text-align: center;
  &__link {
    @extend %btn-gradation;
    padding: 15px;
    &:before {
      display: inline-block;
      vertical-align: middle;
      content: " ";
      width: 51px;
      height: 43px;
      margin-right: 15px;
      background: url(../img/service/logo_toyota.png) no-repeat;
    }
    &:after {
      display: none;
    }
  }
  &-item {
    &__link {
      @extend .btn-contact__link;
      display: block;
      width: auto;
      padding: 25px 20px;
      padding-left: 70px;
      font-size: rem(19);
      &:before {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: url(../img/service/logo_toyota.png);
        background-position: 15px center;
      }
    }
  }
  &.-column {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    width: 670px;
    margin: 0 auto 35px;
    padding: 0;
    li {
      width: 48%;
      margin-bottom: 25px;
    }
  }
@include media(sp) {
  &.-column {
    display: block;
    width: auto;
    margin: 0 0 35px;
    li {
      width: auto;
    }
  }
}
}
