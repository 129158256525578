@charset "utf-8" ;

/* ----------------------------------------------------------------
    core/base
/  --------------------------------------------------------------*/

body {
  color: #000;
  font-family: "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
  line-height: 1.6;
  font-size: 16px;
@include media(sp) {
  line-height: 1.4;
  font-size: 14px;
}
}

a {
  color: #65AADD;
  color: #000;
  text-decoration: none;
  &:hover {
    opacity: .7;
  }
}

img {
  max-width: 100%;
}

p {
  margin: 0 0 1em;
}

[data-show="sp"] {
  @include media(pc) {
    display: none;
  }
}

[data-show="pc"] {
  @include media(sp) {
    display: none;
  }
}

dl,
ul,
ol {
  margin-top: 0;
}

