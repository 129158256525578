@charset "utf-8";

/* ----------------------------------------------------------------
   theme/shop
/  --------------------------------------------------------------*/

.page-header {
  &.-shop {
    background-image: url(../img/shop/title.jpg);
    div {
      padding-left: 80px;
      padding-right: 80px;
      &:after {
        top: 20px;
        right: 20px;
        width: 45px;
        height: 22px;
        background: url(../img/share/h_icon05.png) no-repeat;
        background-size: contain;
      }
    }
  }
@include media(sp) {
  &.-shop {
    div {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}
}

.shop-list {
  &-section {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    padding-bottom: 25px;
    border-bottom: 2px dotted #0071b9;
    &:last-child {
      border: 0;
    }
  }
  &-item {
    width: 300px;
    margin-right: 50px;
    padding-bottom: 30px;
    background: #fff;
    text-align: center;
    &:nth-child(3n) {
      margin: 0;
    }
    &__img {
      margin: 0 0 15px;
    }
    &__title {
      display: inline-block;
      min-width: 180px;
      margin: 0 0 10px;
      border-bottom: 2px solid #0071b9;
      font-size: rem(21);
    }
    &__address {
      margin: 0;
    }
    &__tel {
      &:before {
        display: inline-block;
        content: " ";
        width: 16px;
        height: 13px;
        background: url(../img/shop/icon_tel.png) no-repeat;
      }
    }
    &-btn {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      font-size: rem(14);
      li {
        margin-right: 10px;
        &:last-child {
          margin: 0;
        }
      }
      a {
        display: block;
        width: 6em;
        padding: 5px 10px;
        color: #fff;
        &.-map {
          background: #00b900;
        }
        &.-detail {
          background: #53d1ed;
        }
      }
    }
  }
@include media(sp) {
  &-section {
    display: block;
    margin-bottom: 30px;
    padding-bottom: 0;
  }
  &-item {
    margin: 0 auto 30px !important;
  }
}

}

.shop-detail {
  &__title {
    margin: 0 auto 50px;
    text-align: center;
    font-size: rem(26);
    span {
      display: inline-block;
      min-width: 270px;
      padding-bottom: 5px;
      border-bottom: 5px solid #84e9af;
    }
  }
  &-status {
    list-style: none;
    margin: 0 0 35px;
    padding: 0;
    display: flex;
    justify-content: center;
    opacity: .5;
    li {
      min-width: 87px;
      margin-right: 10px;
      padding: 5px 15px;
      background: #fff;
      color: #0071b9;
      border: 2px solid #0071b9;
      font-size: rem(14);
      text-align: center;
      box-sizing: border-box;
      font-weight: bold;
      &:last-child {
        margin: 0;
      }
    }
  }
  #slider {
    margin-bottom: 50px;
  }
@include media(sp) {
  &__title {
    margin-bottom: 30px;
  }
  &-status {
    margin: 0 15px 35px;
    justify-content: flex-start;
    flex-wrap: wrap;
    li {
      min-width: 10px;
      margin-right: 10px;
      margin-bottom: 10px !important;
    }
  }
}
  &-topic {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    padding: 45px 60px;
    background: #fff;
    &__title {
      @extend %font-notosans;
      margin: 0;
      margin-right: 90px;
      text-align: center;
      line-height: 1;
      font-size: rem(16);
      span {
        position: relative;
        display: inline-block;
        margin-bottom: 10px;
        padding: 0 20px 10px;
        font-size: rem(30);
        border-bottom: 5px solid #f8a5a5;
        &:after {
          position: absolute;
          display: block;
          content: " ";
          right: -40px;
          top: -20px;
          width: 53px;
          height: 33px;
          background: url(../img/shop/icon_topics.png) no-repeat;
        }
      }
    }
    &-list {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        display: flex;
        margin-bottom: 5px;
      }
      .date {
        flex-grow: 0;
        flex-shrink: 0;
        margin-right: 2em;
      }
    }
  }
@include media(sp) {
  &-topic {
    display: block;
    padding: 30px;
    &__title {
      margin: 0 0 20px;
    }
  }
}

  &-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    &-item {
      width: 480px;
      padding: 15px;
      background: #fff;
      box-sizing: border-box;
      &__title {
        padding-left: 10px;
        border-left: 8px solid #84e9af;
        font-size: rem(16);
        span {
          margin-right: 10px;
          font-size: rem(30);
        }
      }
      &-table {
        width: 100%;
        margin-bottom: 25px;
        border-collapse: collapse;
        border-spacing: 0;
        border-top: 2px solid #ccc;
        th,td {
          padding: 10px;
          border-bottom: 1px solid #ccc;
          font-weignt: normal;
          vertical-align: top;
          text-align: left;
        }
        th {
          width: 5em;
        }
        dl {
          margin: 0;
          dt {
            float: left;
            clear: left;
            width: 5em;
          }
        }
      }
      .map {
        position: relative;
        width: 100%;
        height: 320px;
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 0;
        }
      }
    }
  }
@include media(sp) {
  &-section {
    display: block;
    &-item {
      width: auto;
    }
  }
}
}
