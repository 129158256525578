@charset "utf-8";

/* ----------------------------------------------------------------
   component/page-header
/  --------------------------------------------------------------*/

.page-header {
  position: relative;
  margin: 0 0 120px;
  height: 300px;
  box-sizing: border-box;
  background-position: center top;
  background-size: cover;
  &__title {
    position: absolute;
    min-width: 270px;
    max-width: 370px;
    margin: 0 auto;
    text-align: center;
    box-sizing: border-box;
    bottom: -70px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 0px 2px 0px #999;
    div {
      position: relative;
      padding: 30px 35px 20px;
      z-index: 100;
      background: rgba(255,255,255,.9);
      line-height: 0;
      &:after {
        position: absolute;
        display: inline-block;
        content: " ";
      }
    }
    &:before {
      display: block;
      content: " ";
      position: absolute;
    	top: 100%;
    	left: 50%;
    	width: 30px;
    	height: 30px;
    	margin-top: -15px;
    	margin-left: -15px;
      background: #fff;
      transform: rotate(-45deg);
    	box-shadow: 0px 0px 4px 0px #999;
    	z-index: 0;
    }
  }
  // 各ページ画像設定
  &.-news {
    background-image: url(../img/news/title.jpg);
    div {
      &:after {
        top: 22px;
        right: 16px;
        width: 44px;
        height: 37px;
        background: url(../img/news/img_title.png) no-repeat;
      }
    }
  }

@include media(sp) {
  height: 150px;
  &__title {
    min-width: auto;
    max-width: auto;
    width: calc(100% - 60px);
    div {
      padding: 20px 15px;
    }
    &:before {
      top: 100%;
    }
  }
}
}

