@charset "utf-8";

/* ----------------------------------------------------------------
   theme/archive-detail
/  --------------------------------------------------------------*/

.archive-detail {
  width: 720px;
  padding: 25px;
  padding-bottom: 80px;
  background: rgba(255,255,255, .8);
  box-sizing: border-box;
  box-shadow: 0 0 4px #bbb;
  &-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    padding: 15px 0 10px;
    padding-left: 20px;
    border-left: 12px solid #f8a5a5;
    &__title {
      flex: 2;
      margin: 0;
      margin-right: 1em;
      font-size: rem(24);
    }
    &__date {
      margin: 0;
      margin-right: 10px;
      font-size: rem(14);
      @extend %font-notosans;
    }
    &__category {
      @extend %archive-category;
      margin: 0;
      @extend %font-notosans;
    }
  }
  &-body {
    h1 {
      margin: 80px 0 50px;
      padding: 15px 20px;
      background: #f8a5a5;
      font-size: rem(24);
    }
    h2 {
      position: relative;
      margin: 0 0 40px;
      padding-left: 20px;
      padding-bottom: 5px;
      border-left: 10px solid #f8a5a5;
      font-size: rem(21);
      line-height: 1.2;
      &:after {
        position: absolute;
        left: -10px;
        bottom: -10px;
        display: block;
        content: " ";
        width: calc(100% + 10px);
        height: 1px;
        background: #f8a5a5;
      }
    }
    h3 {
      margin: 0 0 30px;
      padding-left: 30px;
      padding-bottom: 5px;
      border-bottom: 1px solid #f8a5a5;
      font-size: rem(18);
    }
    h4 {
      margin: 0 0 20px;
      padding-left: 30px;
      color: #f8a5a5;
      &:before {
        content: "●";
      }
    }
    p {
      margin-bottom: 2em;
      padding-left: 30px;
    }
  }
  table {
    //width: 100%;
    margin-top: 60px;
    margin-left: 30px;
    margin-bottom: 2em;
    border-collapse: collapse;
    border: 2px solid #f8a5a5;
    th,td {
      padding: 15px 20px;
      border-right: 1px solid #f8a5a5;
      border-bottom: 1px solid #f8a5a5;
      text-align: center;
    }
    th {
      width: 20%;
      background: rgba(548,165,165, .2);
      font-weight: normal;
    }
    tr:last-child th {
      border-bottom: 1px solid #f8a5a5;
    }
  }
  &-btn {
    margin: 80px 0 0;
    text-align: center;
    @extend %font-notosans;
    &__link {
      display: inline-block;
      padding: 10px 40px;
      background: #f8a5a5;
      //border: 1px solid #000;
      border-radius: 6px;
      font-size: rem(18);
    }
  }
@include media(sp) {
  padding: 15px;
  width: auto;
  &-header {
    &__title {
      flex: 0 0 100%;
      margin-right: 0;
      margin-bottom: 10px;
      font-size: rem(20);
    }
  }
  &-body {
    h1 {
      margin: 40px 0 30px;
      padding: 10px 15px;
      font-size: rem(20);
    }
    h2 {
      margin-bottom: 20px;
      padding-left: 10px;
      font-size: rem(18);
    }
    h3 {
      padding-left: 20px;
      font-size: rem(16);
    }
    h4 {
      padding-left: 0;
    }
    p {
      padding-left: 0;
    }
  }
  table {
    margin: 40px 0;
    th,td {
      width: auto;
      padding: 10px;
    }
  }
}
}

.side.-detail {
  margin-top: 0;
  .side-category {
    display: none;
  }
}
