@charset "utf-8";

/* ----------------------------------------------------------------
   layout/back2top
/  --------------------------------------------------------------*/

.back2top {
  position: fixed;
  width: 100px;
  height: 102px;
  right: 5%;
  bottom: 20px;
  margin: 0;
  z-index: 100;
  a {
    display: block;
    width: 100%;
    height: 100%;
    background: url(../img/share/pagetop.png) no-repeat;
    font-size: 0;
  }
@include media(sp) {
  width: 60px;
  height: 62px;
  right: 0;
  bottom: 76px;
  a {
    background-size: contain;
  }
}
}
