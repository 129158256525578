@charset "utf-8";

/* ----------------------------------------------------------------
   component/table
/  --------------------------------------------------------------*/


.table {
  position: relative;
  padding: 60px;
  background: #fff;
  box-shadow: 0 0 2px #bbb;
  &-body {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border-top: 1px solid #0071b9;
    th,td {
      padding: 20px;
      border-bottom: 1px solid #0071b9;
    }
    th {
      width: 150px;
      font-weight: normal;
      text-align: left;
    }
    &__th {
      &--justify {
        span {
          display: block;
          width: 4em;
          text-align-last: justify;

        }
      }
    }
  }
@include media(sp) {
  padding: 20px;
  font-size: rem(13);
  &-body {
    th,td {
      padding: 10px;
    }
    th {
      width: auto;
    }
  }
}
}

