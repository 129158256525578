@charset "utf-8";

/* ----------------------------------------------------------------
   component/list
/  --------------------------------------------------------------*/

.att-list {
  list-style: none;
  margin-left: 0;
  padding: 0;
  font-size: rem(14);
  li {
    text-indent: -1em;
    padding-left: 1em;
    &:before {
      content: "※";
    }
  }
@include media(sp) {
  font-size: rem(12);
}
}
