@charset "utf-8";

/* ----------------------------------------------------------------
   theme/top-bnr
/  --------------------------------------------------------------*/

.top-bnr {
  %__list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 auto 100px;
    padding: 0;
  }
  &__list--large {
    @extend %__list;
    justify-content: center;
    li {
      width: 30%;
      max-width: 375px;
      margin-right: 40px;
      margin-right: 2.8%;
      &:last-child {
        margin: 0;
      }
    }
  }
  &__list--small {
    @extend %__list;
    justify-content: center;

    li {
      width: 170px;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
@media screen and (max-width: 1439px) {
  &__list--small {
    width: 710px;
    li {
      margin-bottom: 5px;
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}
@include media(tab) {
  %__list {
    width: auto;
    margin: 0 20px 20px;
  }
}
@include media(sp) {
  %__list {
    width: auto;
    margin: 0 20px 20px;
  }
  &__list--large {
    flex-direction: column;
    li {
      width: auto;
      max-width: auto;
      margin: 0 0 10px;
    }
  }
  &__list--small {
    width: auto;
    justify-content: space-between;
    li {
      width: 49%;
      margin: 0;
      text-align: center;
    }
  }
}
}
