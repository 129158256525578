@charset "utf-8";

/* ----------------------------------------------------------------
   theme/top-instagram
/  --------------------------------------------------------------*/

.top-instagram {
  .contents-title {
    margin-bottom: 0;
  }
  &__link {
    width: 1000px;
    margin: -2em auto 50px;
    font-weight: bold;
    letter-spacing: .2em;
    text-align: right;
    font-size: rem(18);
    a {
      color: #000;
      &:after {
        display: inline-block;
        width: 40px;
        height: 40px;
        margin-left: .5em;
        content: " ";
        background: url(../img/share/i_instagram.png) no-repeat;
        vertical-align: middle;
      }
    }
  }
@include media(tab) {
  &__link {
    width: auto;
    margin: 0 20px 20px;
  }
}
}

.instafeed {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 100px;
  &-item {
    width: calc(100% / 6);
    margin-right: 25px;
    margin-right: 1.7%;
    margin-bottom: 1.7%;
    img {
      border-radius: 27px;
      height: 100%;
      width: 100%;
    }
  }
@include media(sp) {
  justify-content: space-between;
  margin: 0 20px 40px;
  &-item {
    width: 48%;
    margin: 0 0 10px;
    img {
      border-radius: 10px;
    }
  }
}

}