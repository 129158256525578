@charset "utf-8";

/* ----------------------------------------------------------------
   theme/ownership
/  --------------------------------------------------------------*/


.page-header {
  &.-ownership {
    margin-bottom: 200px;
    background-image: url(../img/ownership/title.jpg);
    div {
      padding-left: 60px;
      padding-right: 60px;
      &:after {
        display: none;
      }
    }
  }
@include media(sp) {
  &.-ownership {
    margin-bottom: 120px;
    div {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}
}

.text-box {
  display: inline-block;
  margin-top: 60px;
  padding: 20px 80px;
  border: 7px solid #e8e8e8;
  box-sizing: border-box;
  background: #fff;
  &__title {
    font-size: rem(24);
  }
@include media(sp) {
  margin-top: 30px;
  padding: 20px;
  &__title {
    font-size: rem(20);
  }
}
}
.ownership {
  &-confirm {
    margin-bottom: 150px;
    text-align: center;
    font-size: rem(17);
    &__text {
      margin-top: 60px;
      font-size: rem(19);
    }
    &-link {
      list-style: none;
      margin: 40px 0;
      padding: 0;
      font-size: rem(19);
      @extend %font-notosans;
      a {
        display: inline-block;
        &:before {
          display: inline-block;
          content: "";
          width: 16px;
          height: 16px;
          margin-right: .5em;
          background: url(../img/recruit/icon_link.png) no-repeat;
          background-size: contain;
          vertical-align: middle;
        }
      }
    }
  }

@include media(sp) {
  &-confirm {
    margin-bottom: 70px;
    &__text {
      margin-top: 0;
      font-size: rem(16);
    }
  }
  
}
  &-step {
    &-section {
      counter-increment: number;
      &__title {
        display: flex;
        align-items: center;
        margin: 0 0 30px;
        padding: 20px 15px;
        @extend %font-notosans;
        font-size: rem(19);
        font-weight: bold;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        &:before {
          display: inline-block;
          content: counter(number)".";
          margin-right: 10px;
          font-size: rem(58);
          line-height: 1;
        }
      }
      &-detail {
        margin-bottom: 50px;
        padding-left: 80px;
        ol {
          list-style: none;
          margin: 0;
          padding: 0;
          li {
            margin-bottom: 1em;
          }
        }
      }
      .text-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 15px;
        margin-right: 60px;
        padding: 15px 30px;
        &__text1,
        &__text2 {
          font-weight: bold;
        }
        p {
          margin: 0;
        }
      }
      & + & {
        margin-top: 70px;
      }
      &:last-child {
        .ownership-step-section__title {
          align-items: flex-start;
        }
      }
    }
  }

@include media(sp) {
  &-step {
    margin-bottom: 50px;
    &-section {
      &__title {
        align-items: flex-start;
        font-size: rem(16);
        &:before {
          font-size: rem(40);
        }
      }
      &-detail {
        margin: 0 15px 30px;
        padding: 0;
      }
      .text-box {
        display: block;
        margin: 0 0 15px;
        padding: 15px;
        p {
          margin-bottom: 5px;
        }
      }
      & + & {
        margin-top: 30px;
      }
      &:first-child {
        .ownership-step-section__title {
          align-items: center;
        }
      }
    }
  }
}

  &-document {
    margin-bottom: 90px;
    &-section {
      counter-increment: number;
      &__title {
        @extend .ownership-step-section__title;
      }
      &-detail {
        @extend .ownership-step-section-detail;
        ol {
          margin-bottom: 50px;
          li {
            margin-bottom: 0;
            .text-box {
              display: inline-block;
              margin: 15px 0;
              padding: 15px 30px;
              p,ol {
                margin: 0;
              }
            }
          }
        }
      }
      .document-headline {
        margin: 0 0 5px;
        font-size: rem(19);
      }
      .document-list {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          &:before {
            display: inline-block;
            content: "";
            width: 16px;
            height: 16px;
            margin-right: .5em;
            background: url(../img/recruit/icon_link.png) no-repeat;
            background-size: contain;
            vertical-align: middle;
            margin-top: -3px;
          }
        }
      }
    }
  }

@include media(sp) {
  &-document {
    margin-bottom: 50px;
    &-section {
      &-detail {
        ol {
          margin-bottom: 30px;
          li {
            .text-box {
              padding: 15px;
            }
          }
        }
      }
    }
  }
}


  &-download {
    &-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      list-style: none;
      margin: 0 70px 50px;
      padding: 0;
      li {
        width: 49%;
        margin-bottom: 20px;
        background: #fff;
        font-size: rem(20);
        a {
          display: flex;
          align-items: center;
          min-height: 100px;
          padding: 15px;
          padding-left: 110px;
          background: url(../img/ownership/icon_pdf.png) 15px center no-repeat;
          border: 7px solid #e8e8e8;
          box-sizing: border-box;
        }
        &:nth-child(even) {
          a {
            padding-left: 80px;
          }
        }
      }
    }
  }
@include media(sp) {
  &-download {
    &-list {
      display: block;
      margin: 0 0 50px;
      li {
        width: auto;
        font-size: rem(16);
        a {
          padding: 15px;
          padding-left: 80px;
        }
      }
    }
  }
}

  &-info {
    text-align: center;
    p {
      margin: 0 0 5px;
    }
    &__headline {
      display: inline-block;
      margin: 0 0 5px;
      padding: 5px 10px;
      background: #84e9af;
      font-size: rem(16);
      font-weight: normal;
    }
  }
}

