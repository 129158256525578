@charset "utf-8";

/* ----------------------------------------------------------------
   theme/company
/  --------------------------------------------------------------*/


.page-header {
  &.-company {
    margin-bottom: 200px;
    background-image: url(../img/company/title.jpg);
    div {
      padding-left: 60px;
      padding-right: 60px;
      &:after {
        top: 30px;
        right: 30px;
        width: 37px;
        height: 26px;
        background: url(../img/company/img_title.png) no-repeat;
      }
    }
  }
  &.-search {
    margin-bottom: 200px;
    background-image: url(../img/search/title.jpg);
    div {
      padding-left: 60px;
      padding-right: 60px;
      &:after {
        display: none;
      }
    }
  }
@include media(sp) {
  &.-company,
  &.-search {
    margin-bottom: 120px;
    div {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}
}

.company {
  &-philosophy {
    margin-bottom: 200px;
    text-align: center;
    &__title {
      color: #0071b9;
      font-size: rem(34);
    }
    &__text {
      color: #0071b9;
      font-size: rem(36);
    }
  }
  &-img {
    position: absolute;
    top: 45px;
    right: 45px;
    margin: 0;
    padding-left: 30px;
    background: #fff;
  }
@include media(sp) {
  &-philosophy {
    margin-bottom: 70px;
    &__title {
      font-size: rem(24);
    }
  }
  &-img {
    position: static;
    margin: 0 0 20px;
    padding: 0;
    text-align: center;
  }
}

}
