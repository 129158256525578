@charset "utf-8";

/* ----------------------------------------------------------------
   theme/character
/  --------------------------------------------------------------*/


@mixin width( $parentPxWidth, $pxWidth ){
  width: floor((( $pxWidth / $parentPxWidth ) * 10000% )) / 100;
}

@mixin height($parentPxHeight, $pxHeight){
    height: floor((($pxHeight / $parentPxHeight) * 10000%)) / 100;
}

@mixin margin($parentPxWidth, $mTop, $mRight, $mBottom, $mLeft){
  margin : floor((($mTop / $parentPxWidth) * 10000%)) / 100 floor((($mRight / $parentPxWidth) * 10000%)) / 100 floor((($mBottom / $parentPxWidth) * 10000%)) / 100 floor((($mLeft / $parentPxWidth) * 10000%)) / 100;
}

@mixin padding($parentPxWidth, $pTop, $pRight, $pBottom, $pLeft){
  padding : floor((($pTop / $parentPxWidth) * 10000%)) / 100 floor((($pRight / $parentPxWidth) * 10000%)) / 100 floor((($pBottom / $parentPxWidth) * 10000%)) / 100 floor((($pLeft / $parentPxWidth) * 10000%)) / 100;
}

// left値
@mixin positionL($parentPxWidth, $pxLeft){
    left: floor((($pxLeft / $parentPxWidth) * 10000%)) / 100;
}
// top値
@mixin positionT($parentPxHeight, $pxTop){
    top: floor((($pxTop / $parentPxHeight) * 10000%)) / 100;
}
// right値
@mixin positionR($parentPxWidth, $pxRight){
    right: floor((($pxRight / $parentPxWidth) * 10000%)) / 100;
}
// bottom値
@mixin positionB($parentPxHeight, $pxBottom){
    bottom: floor((($pxBottom / $parentPxHeight) * 10000%)) / 100;
}

@keyframes jump {
	0% {
		transform: translate(0, 0);
	}
	80% {
		transform: translate(0, 0);
	}
	90% {
		transform: translate(0, -20px);
	}
	100% {
		transform: translate(0, 0);
	}
}

@keyframes swing {
	0% {
		transform: rotate(0deg);
	}
	25% {
		transform: rotate(10deg);
	}
	50% {
		transform: rotate(0deg);
	}
	75% {
		transform: rotate(-10deg);
	}
	100% {
		transform: rotate(0deg);
	}
}

@keyframes rotate {
	to {
		transform: rotate(-30deg);
	}
}

@keyframes rotate2 {
	to {
		transform: rotate(30deg);
	}
}

@keyframes bye {
  to {
    background-position: -334px 0;
  }
}

body.-character {
  .l-content {
    background-image: url(../img/character/bg.png);
    background-color: #a0d8ef;
  }
}

.page-header {
  &.-character {
    margin-bottom: 200px;
    background-image: url(../img/character/bg_title.png);
    div {
      padding-left: 60px;
      padding-right: 60px;
      &:after {
        display: none;
      }
    }
  }
@include media(sp) {
  &.-character {
    margin-bottom: 120px;
    div {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}
}

.character {
  &-headline {
    width: 68.4%;
    margin: 0 auto;
    text-align: center;
    line-height: 0;
  }
  &-main {
    @extend %contents-width;
    // ほ・えーるくん
    .c01 {
      position: relative;
      * { margin: 0; }
      text-align: center;
      &-main {
        position: relative;
        @include padding(1000,50,0,0,0);
        line-height: 0;
        &-img {
          width: 48.7%;
          @include margin(1000,0,0,0,300);
          animation: jump 3s 1.5s infinite;
        }
        &-headline,
        &-text01,
        &-text02,
        &-text03,
        &-pet {
          position: absolute;
        }
        &-headline {
          width: 30.8%;
          right: .5%;
          @include positionB(572,92);
        }
        // ニタリ鯨
        &-text01 {
          width: 13.4%;
          left: 17%;
          @include positionT(572,80);
        }
        // 鳴子カラー
        &-text02 {
          width: 16.9%;
          right: 23.3%;
          @include positionB(572,18);
        }
        // よろしくねっつ
        &-text03 {
          width: 29.6%;
          top: 0;
          right: 1.5%;
          animation: rotate2 2s steps(2) infinite;
        }
        // スパにゃ
        &-pet {
          width: 19.2%;
          left: 9.8%;
          @include positionB(572,37);
        }
      }
      &-text {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        margin-top: -5px;
        font-size: rem(14);
        &-section {
          width: 15.3%;
          text-align: left;
          margin-left: 2%;
          margin-top: 25px;
          &__title {
            @include width(153,102);
            margin: 0 auto 15px;
            line-height: 0;
          }
          &:first-child {
            position: relative;
            &:after {
              position: absolute;
              display: block;
              content: "";
              top: 40px;
              right: -20px;
              width: 22px;
              height: 22px;
              background: url(../img/character/c01_02_02.png) no-repeat;
            }
          }
        }
      @include media(sp) {
        display: block;
        margin: 0;
        &-section {
          display: flex;
          align-items: center;
          width: auto;
          &__title {
            flex-shrink: 0;
            width: auto;
            margin: 0;
            margin-right: 10px;
          }
          &:first-child {
            &:after {
              top: auto;
              right: auto;
              left: 40px;
              bottom: -25px;
            }
          }
        }
      }
      }
      &-profile {
        flex-shrink: 0;
        margin-left: 2%;
      }
    }
    // スパにゃ
    .c02 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px;
      &-main {
        position: relative;
        flex-shrink: 0;
        @include padding(260,0,0,0,47);
        padding-left: 4.7%;
        &-img {
          width: 100%;
          animation: swing 2s -1s linear infinite;
        }
        &-headline {
          position: absolute;
          width: 23.6%;
          @include width(260,236);
          @include positionR(260,-236);
        }
        // よろしくにゃ
        &-text01 {
          position: absolute;
          @include width(260,263);
          left: 2.4%;
          top: -130px;
          animation: rotate 2s steps(2) infinite;
        }
      }
      &-text {
        display: flex;
        margin-top: 130px;
        margin-left: 35px;
        font-size: rem(14);
        &-section {
          &__title {
            text-align: center;
            margin-bottom: 15px;
            img:first-child {
              margin-right: 60px;
            }
          }
        }
      }
      &-profile {
        flex-shrink: 0;
        margin-left: 27px;
        margin-top: 30px;
      }
    @include media(sp) {
      position: relative;
      display: block;
      margin-top: 20px;
      &-main {
        padding-left: 0;
        &-img {
          width: 39%;
          padding-top: 20%;
        }
        &-headline {
          width: 35.4%;
          right: auto;
          left: 39%;
          top: 25%;
        }
        &-text01 {
          width: 39.4%;
          top: 0;
        }
      }
      &-text {
        //position: absolute;
        display: block;
        float: right;
        width: 62%;
        margin: 0;
        right: 0;
        margin-top: -38%;
        &-section {
          top: 0;
          &__title {
            margin-bottom: 10px;
            img {
              width: 51px;
            }
            img:first-child {
              margin-right: 10px;
            }
          }
        }
      }
      &-profile {
        flex-shrink: 0;
        text-align: center;
        margin: 0;
        margin-top: 22%;
      }
    }
    }
  }

  &-instagram {
    margin-top: 30px;
    background: #fff;
    &:before,
    &:after {
      position: relative;
      top: -15px;
      display: block;
      content: "";
      width: 100%;
      height: 15px;
      background: url(../img/character/bg_wave.png) repeat-x;
    }
    &:after {
      top: auto;
      bottom: -15px;
      background: url(../img/character/bg_wave.png) bottom left repeat-x;
    }
    &-inner {
      @extend %contents-width;
      padding: 40px 0 120px;
      position: relative;
    }
    &__title {
      margin: 0 0 70px;
      margin-left: 20%;
    }
    &__text {
      position: absolute;
      top: -47px;
      right: 0;
    }
    .instafeed {
      margin: 0;
      &-item {
        width: calc(94% / 3);
        margin-right: 3%;
        img {
          border-radius: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  @include media(sp) {
    &-inner {
      padding: 40px 0;
    }
    &__title {
      margin: 0 0 20px;
    }
    &__text {
      width: 50%;
      top: -50px;
    }
    .instafeed {
      margin: 0;
    }
  }
  }

  &-goods {
    position: relative;
    @extend %contents-width;
    padding-top: 150px;
    &__title {
      @extend .ttl-l2-2;
      span {
        border-bottom: 5px solid #f8a5a5;
      }
    }
    &__text {
      .text {
        position: absolute;
        left: 95px;
        top: -43px;
      }
      .c02 {
        position: absolute;
        top: 50px;
        left: 18px;
      }
    }
    &__img {
      display: flex;
      justify-content: space-between;
      margin-bottom: 130px;
    }
    &-message {
      position: relative;
      width: 509px;
      margin: 0 auto 130px;
      .c02 {
        position: absolute;
        left: -8px;
        top: -72px;
        animation: swing 2s -1s linear infinite;
      }
      .c01 {
        position: absolute;
        bottom: 12px;
        right: -90px;
        width: 167px;
        height: 164px;
        background: url(../img/character/end03.png) no-repeat;
        background-size: auto 164px;
        animation: bye 2s steps(2) infinite;
      }
    }
  @include media(sp) {
    padding-top: 70px;
    &__text {
      .text {
        width: 52.8%;
        position: absolute;
        left: 45px;
        top: -24px;
      }
      .c02 {
        width: 13.2%;
        position: absolute;
        top: 25px;
        left: 0px;
      }
    }
    &__img {
      display: block;
    }
    &-message {
      width: auto;
      .c02 {
        width: 23.1%;
        top: -35px;
        left: 0;
      }
      .c01 {
        //width: 37.5%;
        right: -40px;
        //top: 0;
        bottom: 30px;
        transform: scale(0.7,0.7)
      }
    }
  }
  }

}
