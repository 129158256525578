@charset "utf-8";

/* ----------------------------------------------------------------
   component/contents
/  --------------------------------------------------------------*/

.contents {
  // wrap
  &-wrap {
    @extend %contents-width;
  }
  // section
  &-section {
    margin-bottom: 30px;
    & + & {
      margin-top: 80px;
    }
    @include media(sp) {
      padding: 0 20px;
    }
  }
  // title
  &-title {
    margin: 110px 0 50px;
    text-align: center;
    @include media(sp) {
      margin: 60px 0 20px;
    }
  }
}


